import { useTranslation } from "react-i18next";
import { Text } from "../../common/components/atoms/typography/Text";
import { FileUploader } from "../../common/components/organism/fileUploader";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle";
import { IconDatabase } from "@tabler/icons-react";
import { BreadcrumbsLayout } from "../../common/components/layout/BreadcrumbsLayout";
import { useCurrentCompanyDomain } from "src/common/company-domain/useCurrentCompanyDomain";
import { useNavigate } from "react-router";
import { SieUploader } from "src/features/connector/sie/SieUploader";

export const UploadSiePage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { companyDomain } = useCurrentCompanyDomain();

	const pageName = t("Upload SIE4-files");
	useDocumentTitle(pageName);

	return (
		<BreadcrumbsLayout
			breadcrumbs={[{ icon: <IconDatabase /> }, { name: pageName }]}
		>
			<Text className="mb-2" size="md">
				{t("Here you can upload SIE4 files from your financial system.")}
			</Text>
			<SieUploader
				onUpload={({ sourceId, fileName }) => {
					navigate({
						pathname: `/company-domains/${companyDomain.id}/data-sources/new/sie/${sourceId}`,
						search: `?fileName=${fileName}`,
					});
				}}
				render={({ uploadFile, isUploading }) => {
					return (
						<FileUploader
							dropText={t("Drag and drop a SIE4 file here (one file only)")}
							accept={{ "text/plain": [".se"] }}
							maxFiles={1}
							onDraggedFiles={async (files) => {
								await uploadFile(files[0]);
							}}
							isLoading={isUploading}
						/>
					);
				}}
			/>
		</BreadcrumbsLayout>
	);
};
