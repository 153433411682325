import { useTranslation } from "react-i18next";
import { BreadcrumbsLayout } from "../../common/components/layout/BreadcrumbsLayout.tsx";
import { Outlet } from "react-router";
import { IconSettings, IconUsers } from "@tabler/icons-react";
import { SideMenuItem } from "../../common/components/atoms/sideMenu/SideMenuItem.tsx";
import { SideMenu } from "../../common/components/atoms/sideMenu/SideMenu.tsx";

export const SettingsPage = () => {
	const { t } = useTranslation();

	const tabs = [
		{
			path: ".",
			label: t("General"),
			icon: <IconSettings />,
			end: true,
		},
		{
			path: "users",
			label: t("Users"),
			icon: <IconUsers />,
		},
	];

	return (
		<BreadcrumbsLayout
			breadcrumbs={[{ icon: <IconSettings />, name: t("Settings") }]}
		>
			<div className="grid grid-cols-1 gap-4 pt-4 lg:grid-cols-[min-content,auto]">
				<SideMenu>
					{tabs.map((tab) => {
						return (
							<SideMenuItem
								key={tab.path}
								to={tab.path}
								icon={tab.icon}
								title={tab.label}
								end={tab.end}
							/>
						);
					})}
				</SideMenu>
				<div className="grow">
					<Outlet />
				</div>
			</div>
		</BreadcrumbsLayout>
	);
};
