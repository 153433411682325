import { IconPaperclip } from "@tabler/icons-react";
import FileDetails from "./FileDetails";

interface IProps {
	file: File;
	isLoading: boolean;
}

export default function FilePreview(props: IProps) {
	return (
		<div className="rounded-[8px] border border-dashed border-grey-500">
			<div className="flex items-center gap-x-2">
				<div className="ml-2">
					<IconPaperclip />
				</div>
				<div>
					<FileDetails file={props.file || {}} isLoading={props.isLoading} />
				</div>
			</div>
		</div>
	);
}
