import { IconX, type IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	type ComponentPropsWithoutRef,
	forwardRef,
	type ReactElement,
	type ReactNode,
} from "react";
import type { DefaultProps } from "../../../../theme/DefaultProps";
import {
	FeaturedIcon,
	type FeaturedIconColor,
} from "../featuredIcon/FeaturedIcon";
import { Text } from "../typography/Text";
import styles from "./Notification.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "../button/Button.tsx";

export interface NotificationProps
	extends DefaultProps,
		Omit<ComponentPropsWithoutRef<"div">, "title"> {
	onClose?(): void;
	icon?: ReactElement<IconProps>;
	iconColor?: FeaturedIconColor;
	title?: ReactNode;
	children?: ReactNode | undefined;
	disallowClose?: boolean;
}

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
	function Notification(
		{
			className,
			disallowClose,
			title,
			icon,
			iconColor,
			children,
			onClose,
			...others
		},
		ref,
	) {
		const withTitle = title !== undefined;
		const title_ = withTitle ? title : children;
		const message = !withTitle ? undefined : children;
		const { t } = useTranslation();

		return (
			<div
				className={clsx(styles.root, className)}
				role="alert"
				aria-labelledby={`${others.id}-label`}
				ref={ref}
				{...others}
			>
				{icon && (
					<div className={styles.iconContainer}>
						<FeaturedIcon
							size="md"
							theme="light_circle"
							color={iconColor ?? "gray"}
						>
							{icon}
						</FeaturedIcon>
					</div>
				)}

				<div className={styles.body}>
					<Text
						size="sm"
						weight="medium"
						className={styles.title}
						id={`${others.id}-label`}
					>
						{title_}
					</Text>

					{message && (
						<Text
							color="text-grey-500"
							size="sm"
							className={styles.description}
						>
							{message}
						</Text>
					)}
				</div>

				{!disallowClose && (
					<div className="self-start p-2">
						<Button
							size="sm"
							variant="ghost"
							ariaLabel={t("Close")}
							onClick={onClose}
							icon={<IconX />}
						/>
					</div>
				)}
			</div>
		);
	},
);
