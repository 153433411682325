import type { User } from "@auth0/auth0-spa-js";
import {
	redirect,
	redirectDocument,
	type RouteObject,
	type DataStrategyFunction,
	type DataStrategyMatch,
	type DataStrategyResult,
	type LoaderFunction,
	type LoaderFunctionArgs,
} from "react-router";
import { auth0Client } from "src/auth0";
import { store } from "src/common/redux/store";
import { isProduction } from "src/env";
import * as Sentry from "@sentry/react";
import { getDeferredPromise } from "src/common/utils/getDeferredPromise";
import type { ThunkAction } from "@reduxjs/toolkit";

const unloaderEventTarget = new EventTarget();
let previousMatches: string[] = [];

export const dispatchUnloadEvents = (nextMatches: DataStrategyMatch[]) => {
	for (const previousMatch of previousMatches) {
		const match = nextMatches.find((m) => m.route.id === previousMatch);
		if (!match || match.shouldLoad) {
			unloaderEventTarget.dispatchEvent(
				new CustomEvent(`unload-${previousMatch}`),
			);
		}
	}
	previousMatches = nextMatches.map((match) => match.route.id);
};

export const dataStrategy: DataStrategyFunction = async ({ matches }) => {
	dispatchUnloadEvents(matches);

	const promises: Promise<readonly [string, DataStrategyResult]>[] = [];

	async function getMatchPromise(match: DataStrategyMatch) {
		const result = await match.resolve(async (handler) => {
			// add unload handler and pass it to loader as argument
			const onUnload = (fn: () => void) => {
				unloaderEventTarget.addEventListener(`unload-${match.route.id}`, fn, {
					once: true,
				});
			};
			// Whatever you pass to `handler` will be passed as the 2nd parameter
			// to your loader/action
			return handler({ onUnload });
		});

		return [match.route.id, result] as const;
	}

	for (const match of matches) {
		promises.push(getMatchPromise(match));
	}

	const results = await Promise.all(promises);

	return Object.fromEntries(results);
};

type OnUnloadArg = () => void;
type HandlerCtx = { onUnload: (fn: OnUnloadArg) => void };

export const subscribeToQuery = async <TReturn,>({
	args,
	handlerCtx,
	getThunkAction,
	getErrorToThrow,
}: {
	args: LoaderFunctionArgs;
	handlerCtx: unknown;
	getThunkAction: (args: LoaderFunctionArgs) => ThunkAction<any, any, any, any>;
	getErrorToThrow?: (error: any) => void;
}) => {
	const { onUnload } = handlerCtx as HandlerCtx;
	const promise = store.dispatch(getThunkAction(args));
	onUnload(() => {
		promise.unsubscribe();
	});
	const result = await promise;
	if (!result.data) {
		if (getErrorToThrow) {
			throw getErrorToThrow(result.error);
		}
		throw result.error;
	}
	return result.data as TReturn;
};

const isRedirectCallback = (request: Request) => {
	const url = new URL(request.url);
	const { searchParams } = url;
	return (
		(searchParams.has("code") || searchParams.has("error")) &&
		searchParams.has("state")
	);
};

const isAuthenticatingDeferred = getDeferredPromise<void>();

export const createLoaderWithAuthCheck = (
	loader: LoaderFunction<any>,
	options?: { performAuthentication?: boolean },
): LoaderFunction<any> => {
	return async (args, handlerCtx) => {
		let user: User | undefined;
		if (options?.performAuthentication) {
			try {
				if (isRedirectCallback(args.request)) {
					const { appState } = await auth0Client.handleRedirectCallback();
					user = await auth0Client.getUser();
					if (user) {
						const to = appState?.returnTo ?? window.location.pathname;
						throw redirect(to);
					}
				} else {
					await auth0Client.checkSession();
					user = await auth0Client.getUser();
				}

				if (!user) {
					let redirectResponse: Response | undefined;
					await auth0Client.loginWithRedirect({
						appState: {
							returnTo: location.href.replace(location.origin, ""),
						},
						openUrl(url) {
							redirectResponse = redirectDocument(url);
						},
					});
					throw redirectResponse;
				}

				if (isProduction && user.email) {
					Sentry.setUser({ email: user.email });
				}
			} catch (error) {
				if (
					error instanceof Error &&
					error.message === "Please verify your email before logging in."
				) {
					throw redirect("/verify-email");
				}
				throw error;
			} finally {
				isAuthenticatingDeferred.resolve();
			}
		} else {
			await isAuthenticatingDeferred.promise;
			user = await auth0Client.getUser();
		}

		if (!user) {
			return;
		}

		return loader(args, handlerCtx);
	};
};

export function ensureRoutesHaveLoaderFunction(routeObjects: RouteObject[]) {
	for (const routeObject of routeObjects) {
		if (routeObject.loader === undefined) {
			routeObject.loader = () => ({});
		}

		ensureRoutesHaveLoaderFunction(routeObject.children ?? []);
	}
}
