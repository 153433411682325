import { useTranslation } from "react-i18next";
import { IconCirclePlus, IconTrash } from "@tabler/icons-react";
import {
	useRemoveCompanyHierarchyMutation,
	useUpdateCompanyHierarchyMutation,
} from "../../../common/service/nexus/nexusApi.js";
import {
	Card,
	CardHeader,
	CardItemsHeader,
} from "../../planning/components/Card";
import { HierarchyTable } from "../../../common/components/atoms/hierarchyTable/HierarchyTable";
import { useId, useState } from "react";
import { AddCompanyToHierarchyModal } from "./AddCompanyToHierarchyModal";
import { isDefined } from "../../../common/utils/filters/isDefined.js";
import { RemoveCompanyFromHierarchyModal } from "./RemoveCompanyFromHierarchyModal.js";
import type { Hierarchy } from "../../../common/components/atoms/hierarchyTable/types.js";
import { useCurrentCompanyDomain } from "../../../common/company-domain/useCurrentCompanyDomain.js";
import type {
	LegalEntityHierarchy,
	UpdateHierarchyRequestBody,
	LegalEntity,
} from "src/common/service/nexus/endpoints/companyDomain/types.js";
import { Button } from "../../../common/components/atoms/button/Button.js";
import { Select } from "../../../common/components/atoms/select/Select.js";
import { Label } from "../../../common/components/atoms/label/Label.js";
import {
	useLoadedCompanyHierarchy,
	useLoadedLegalEntities,
} from "src/routes/routeDataHooks.js";

const convertHierarchyToUpdateRequest = (
	hierarchy: LegalEntityHierarchy,
): UpdateHierarchyRequestBody => ({
	legalEntityId: hierarchy.legalEntity.id,
	children: hierarchy.children.map(convertHierarchyToUpdateRequest),
});

const convertCompanyHierarchyToHierarchy = (
	companyHierarchy: LegalEntityHierarchy,
): Hierarchy => ({
	id: companyHierarchy.legalEntity.id,
	label: companyHierarchy.legalEntity.name,
	children: companyHierarchy.children.map(convertCompanyHierarchyToHierarchy),
});

const addLegalEntityToCompanyHierarchy = (
	parentId: string,
	child: LegalEntity,
	companyHierarchy: LegalEntityHierarchy,
): LegalEntityHierarchy => {
	if (parentId === companyHierarchy.legalEntity.id) {
		return {
			legalEntity: companyHierarchy.legalEntity,
			children: [
				...companyHierarchy.children,
				{
					legalEntity: child,
					children: [],
				},
			],
		};
	}

	return {
		...companyHierarchy,
		children: companyHierarchy.children.map((hierarchy) =>
			addLegalEntityToCompanyHierarchy(parentId, child, hierarchy),
		),
	};
};

const removeLegalEntityFromCompanyHierarchy = (
	id: string,
	companyHierarchy: LegalEntityHierarchy,
): LegalEntityHierarchy | null => {
	if (id === companyHierarchy.legalEntity.id) {
		return null;
	}

	return {
		...companyHierarchy,
		children: companyHierarchy.children
			.map((child) => removeLegalEntityFromCompanyHierarchy(id, child))
			.filter(isDefined),
	};
};

const extractLegalEntityIdsFromHierarchy = (
	hierarchy: LegalEntityHierarchy | null,
): string[] => {
	if (!hierarchy) {
		return [];
	}

	const ids = [hierarchy.legalEntity.id];
	for (const child of hierarchy.children) {
		ids.push(...extractLegalEntityIdsFromHierarchy(child));
	}
	return ids;
};

export const GroupHierarchyCard = () => {
	const { t } = useTranslation();
	const { companyDomain } = useCurrentCompanyDomain();
	const legalEntities = useLoadedLegalEntities();
	const hierarchy = useLoadedCompanyHierarchy();

	const [updateHierarchy] = useUpdateCompanyHierarchyMutation();
	const [removeHierarchy] = useRemoveCompanyHierarchyMutation();

	const [modalState, setModalState] = useState<{
		legalEntity: LegalEntity;
		variant: "REMOVING" | "ADDING";
	} | null>(null);
	const parentCompanyLabelId = useId();

	const legalEntitiesInHierarchy = extractLegalEntityIdsFromHierarchy(
		hierarchy.hierarchy,
	);

	const availableLegalEntities = legalEntities.filter(
		(legalEntity) => !legalEntitiesInHierarchy.includes(legalEntity.id),
	);

	const closeModal = () => setModalState(null);

	return (
		<Card className="mt-4">
			<CardHeader>
				<CardItemsHeader
					heading={t("Group structure")}
					subHeading={t("Specify the legal entities and their relationships")}
				/>
			</CardHeader>
			{hierarchy.hierarchy ? (
				<>
					<AddCompanyToHierarchyModal
						isOpen={modalState?.variant === "ADDING"}
						availableLegalEntities={availableLegalEntities}
						onAdd={async (legalEntity) => {
							if (modalState && hierarchy.hierarchy) {
								const newHierarchy = addLegalEntityToCompanyHierarchy(
									modalState.legalEntity.id,
									legalEntity,
									hierarchy.hierarchy,
								);

								await updateHierarchy({
									companyDomainId: companyDomain.id,
									hierarchy: convertHierarchyToUpdateRequest(newHierarchy),
								});

								closeModal();
							}
						}}
						onCancel={closeModal}
					/>
					<RemoveCompanyFromHierarchyModal
						isOpen={modalState?.variant === "REMOVING"}
						legalEntity={modalState?.legalEntity}
						onConfirm={async () => {
							if (modalState && hierarchy.hierarchy) {
								const newHierarchy = removeLegalEntityFromCompanyHierarchy(
									modalState.legalEntity.id,
									hierarchy.hierarchy,
								);

								if (!newHierarchy) {
									await removeHierarchy({
										companyDomainId: companyDomain.id,
									});
								} else {
									await updateHierarchy({
										companyDomainId: companyDomain.id,
										hierarchy: convertHierarchyToUpdateRequest(newHierarchy),
									});
								}

								closeModal();
							}
						}}
						onCancel={closeModal}
					/>
					<HierarchyTable
						title={t("Legal entity")}
						expandedByDefault
						hierarchy={convertCompanyHierarchyToHierarchy(hierarchy.hierarchy)}
						renderActions={(hierarchy) => {
							return (
								<>
									<Button
										ariaLabel={t("Add subsidiary")}
										variant="ghost"
										disabled={availableLegalEntities.length === 0}
										onClick={() => {
											const matchingLegalEntity = legalEntities.find(
												(legalEntity) => legalEntity.id === hierarchy.id,
											);

											if (matchingLegalEntity) {
												setModalState({
													legalEntity: matchingLegalEntity,
													variant: "ADDING",
												});
											}
										}}
										icon={<IconCirclePlus />}
									/>

									<Button
										ariaLabel={t("Delete legal entity")}
										variant="ghost"
										onClick={() => {
											const matchingLegalEntity = legalEntities.find(
												(legalEntity) => legalEntity.id === hierarchy.id,
											);

											if (matchingLegalEntity) {
												setModalState({
													legalEntity: matchingLegalEntity,
													variant: "REMOVING",
												});
											}
										}}
										icon={<IconTrash />}
									/>
								</>
							);
						}}
					/>
				</>
			) : (
				<div className="p-4">
					<Label id={parentCompanyLabelId}>{t("Parent company")}</Label>
					<Select
						aria-labelledby={parentCompanyLabelId}
						options={legalEntities.map((legalEntity) => ({
							label: legalEntity.name,
							value: legalEntity.id,
						}))}
						value={null}
						placeholder={t("Select parent company")}
						onChange={(legalEntityId) => {
							if (legalEntityId) {
								updateHierarchy({
									companyDomainId: companyDomain.id,
									hierarchy: {
										legalEntityId,
										children: [],
									},
								});
							}
						}}
					/>
				</div>
			)}
		</Card>
	);
};
