import type { Placement } from "@floating-ui/react";
import { IconInfoCircle } from "@tabler/icons-react";
import { Popover } from "components/popoverMenu/Popover";
import { PopoverContent } from "components/popoverMenu/PopoverContent";
import { PopoverTrigger } from "components/popoverMenu/PopoverTrigger";
import { useEffect, useState, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

const updateExpires = {
	"data-sources-sidebar": new Date("2025-03-01"),
	"sie-upload-button": new Date("2025-03-01"),
} satisfies Record<string, Date>;

if (import.meta.env.DEV) {
	for (const [id, expires] of Object.entries(updateExpires)) {
		if (expires < new Date()) {
			throw new Error(`Quick update ${id} expired. Please remove it.`);
		}
	}
}

const quickUpdateLocalStoragePrefix = "expn-quick-update-";

function clearExpiredQuickUpdates() {
	const localStorageState = { ...localStorage };
	const dismissed = new Set<QuickUpdateId>();
	for (const key of Object.keys(localStorageState)) {
		if (key.startsWith(quickUpdateLocalStoragePrefix)) {
			const updateId = key.replace(
				quickUpdateLocalStoragePrefix,
				"",
			) as QuickUpdateId;
			const expires = updateExpires[updateId];
			if (!expires || expires < new Date()) {
				localStorage.removeItem(key);
			} else {
				dismissed.add(updateId);
			}
		}
	}

	return dismissed;
}

let dismissedUpdates = clearExpiredQuickUpdates();
export type QuickUpdateId = keyof typeof updateExpires;

function persistDismissal(id: QuickUpdateId) {
	localStorage.setItem(`${quickUpdateLocalStoragePrefix}${id}`, "true");
	dismissedUpdates = clearExpiredQuickUpdates();
}

export const QuickUpdatePopover = ({
	id,
	children,
	update,
	placement,
	delay = 400,
}: {
	id: QuickUpdateId;
	children?: ReactNode;
	update: string;
	placement: Placement;
	delay?: number;
}) => {
	const [open, setOpen] = useState(false);
	const expires = updateExpires[id];
	const hasDismissed = dismissedUpdates.has(id);
	const isDisabled =
		navigator.webdriver || hasDismissed || expires < new Date();

	useEffect(() => {
		if (isDisabled) {
			return;
		}
		const timeoutId = setTimeout(() => {
			setOpen(true);
		}, delay);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [delay, isDisabled]);

	const { t } = useTranslation();

	if (isDisabled) {
		return children;
	}

	return (
		<Popover
			role="tooltip"
			withArrow
			placement={placement}
			closeOnEscape={false}
			open={open}
			setOpen={(open) => {
				setOpen(open);
			}}
			disableInteractions
			offset={10}
		>
			<PopoverTrigger asChild>{children}</PopoverTrigger>
			<PopoverContent>
				<Popover.ContentContainer className="max-w-80 p-4">
					<div className="flex gap-4">
						<IconInfoCircle className="text-blue-500 shrink-0" size={28} />
						<div>
							<div className="text-gray-700 mb-2">{update}</div>
							<div>
								<button
									type="button"
									className="font-medium hover:underline text-gray-700 outline-none"
									onClick={() => {
										persistDismissal(id);
										setOpen(false);
									}}
								>
									{t("Got it!")}
								</button>
							</div>
						</div>
					</div>
				</Popover.ContentContainer>
			</PopoverContent>
		</Popover>
	);
};
