import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getInvoice } from "../../common/service/storage";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle";
import { BreadcrumbsLayout } from "../../common/components/layout/BreadcrumbsLayout";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { Alert } from "../../common/components/atoms/alert/Alert";
import { getAccessToken } from "src/auth0";

export const LegacyInvoice = () => {
	const { t } = useTranslation();
	const [fetching, setFetching] = useState<boolean>();
	const [errorMessage, setErrorMessage] = useState<string>();
	const [url, setUrl] = useState<string>();

	useEffect(() => {
		setFetching(true);
		const path = window.location.pathname;
		const fetchInvoice = async () => {
			try {
				const accessToken = await getAccessToken();
				const blob = await getInvoice(path, accessToken);
				const file = new Blob([blob], { type: "application/pdf" });
				const fileUrl = URL.createObjectURL(file);
				setUrl(fileUrl);
				setErrorMessage("");
			} catch (error) {
				if (error instanceof Error) {
					setErrorMessage(error.message);
				}
			}
		};
		setFetching(false);
		fetchInvoice();
	}, []);

	const pageName = t("Invoice");
	useDocumentTitle(pageName);

	return (
		<BreadcrumbsLayout breadcrumbs={[{ name: pageName }]}>
			{fetching ? (
				<LoadingState />
			) : errorMessage ? (
				<Alert title="Fel" color="error" supportingText={errorMessage} />
			) : (
				<iframe
					src={url}
					title="invoice"
					style={{ width: "100%", height: "700px" }}
				/>
			)}
		</BreadcrumbsLayout>
	);
};
