import { createContext, type ReactNode, useMemo } from "react";
import { useLocation } from "react-router";
import { useCurrentCompanyDomain } from "../company-domain/useCurrentCompanyDomain.js";
import { useCurrentLegalEntity } from "../legal-entities/useCurrentLegalEntity.js";
import { useLegalEntityHierarchy } from "../legal-entities/useLegalEntityHierarchy.js";
import { LegalEntityMode } from "./legalEntityMode";
import { useUser } from "./userContextUtils";
import { COMPANYADMIN } from "src/common/service/users.js";

type LegalEntityModeContextType =
	| {
			availableLegalEntityMode: LegalEntityMode[];
			legalEntityMode: LegalEntityMode;
	  }
	| undefined;

const legalEntityModes: LegalEntityMode[] = [
	LegalEntityMode.Corporate,
	LegalEntityMode.Company,
];

export const LegalEntityModeContext =
	createContext<LegalEntityModeContextType>(undefined);

LegalEntityModeContext.displayName = "LegalEntityModeContext";

function useProvideLegalEntityMode() {
	const { isLegalEntityPartOfCorporateGroup } = useLegalEntityHierarchy();
	const { legalEntity } = useCurrentLegalEntity();
	const { companyDomain } = useCurrentCompanyDomain();

	const { hasPermissions } = useUser();

	const availableLegalEntityMode: LegalEntityMode[] = useMemo(() => {
		const hasCorporateGroup = legalEntity
			? isLegalEntityPartOfCorporateGroup(legalEntity.id)
			: false;
		const isAllowedToViewCorporateGroup = hasPermissions(companyDomain.id, [
			COMPANYADMIN,
		]);

		return hasCorporateGroup && isAllowedToViewCorporateGroup
			? legalEntityModes
			: [];
	}, [
		companyDomain.id,
		isLegalEntityPartOfCorporateGroup,
		legalEntity,
		hasPermissions,
	]);

	const selectedLegalEntityMode = new URLSearchParams(useLocation().search).get(
		"legal_entity_mode",
	);

	return useMemo(() => {
		const foundLegalEntityMode = availableLegalEntityMode.find((mode) => {
			return mode === selectedLegalEntityMode;
		});

		let legalEntityMode = legalEntityModes[0];
		if (foundLegalEntityMode) {
			legalEntityMode = foundLegalEntityMode;
		} else if (availableLegalEntityMode.length === 0) {
			legalEntityMode = legalEntityModes[1];
		}

		return {
			availableLegalEntityMode,
			legalEntityMode,
		};
	}, [availableLegalEntityMode, selectedLegalEntityMode]);
}

interface Props {
	children?: ReactNode | undefined;
}

export function ProvideLegalEntityMode({ children }: Props) {
	const legalEntityMode = useProvideLegalEntityMode();
	return (
		<LegalEntityModeContext.Provider value={legalEntityMode}>
			{children}
		</LegalEntityModeContext.Provider>
	);
}
