import { useTranslation } from "react-i18next";
import type { User } from "../../common/service/Types/UserTypes";
import { IconTrash } from "@tabler/icons-react";
import { Avatar } from "../../common/components/atoms/avatar/Avatar";
import { Chip } from "../../common/components/atoms/chip/Chip";
import { Anchor } from "../../common/components/atoms/anchor/Anchor";
import { getGravatarUrl } from "../planning/utils/getGravatarUrl.js";
import { getGroupName } from "./getGroupName.js";
import { Button } from "../../common/components/atoms/button/Button.js";
import { TooltipTrigger } from "components/tooltip/TooltipTrigger.js";
import { TooltipContent } from "components/tooltip/TooltipContent.js";
import { GridColumn } from "src/features/planning/scenario/common/grid/GridColumn.js";
import { GridRow } from "src/features/planning/scenario/common/grid/GridRow.js";
import { Tooltip } from "components/tooltip/Tooltip.js";
import { COMPANYADMIN } from "src/common/service/users.js";

interface UserTableRowProps {
	user: User;
	onDeleteUser: (user: User) => void;
	onEditUser: (user: User) => void;
	companyId: string;
}

export const UserTableRow = ({
	user,
	companyId,
	onDeleteUser,
	onEditUser,
}: UserTableRowProps) => {
	const { t } = useTranslation();

	const accessGroups = user.accessGroups.filter((group) => {
		return group.companyId === Number(companyId);
	});
	accessGroups.sort((a, b) => {
		const aName = getGroupName({ t, groupName: a.name });
		const bName = getGroupName({ t, groupName: b.name });
		return aName.localeCompare(bName);
	});

	return (
		<GridRow>
			<GridColumn>
				<div className="flex items-center gap-2">
					<Avatar src={getGravatarUrl(user.email)} size="sm" alt="" />
					<Anchor<"a">
						onClick={(event) => {
							event.preventDefault();
							onEditUser(user);
						}}
					>
						{user.name}
					</Anchor>
					{user.superAdmin && (
						<Chip color="purple" size="small">
							Superadmin
						</Chip>
					)}
				</div>
			</GridColumn>
			<GridColumn>
				{accessGroups.some((role) => role.name === COMPANYADMIN)
					? t("CompanyAdmin")
					: t("User")}
			</GridColumn>
			<GridColumn>{user.email}</GridColumn>
			<GridColumn>
				<div className="flex gap-2">
					{accessGroups.map((group) => {
						const colorClass = group.colorCode ? undefined : "bg-purple-500";
						return (
							<Tooltip key={group.groupId}>
								<TooltipTrigger>
									<div
										className={`rounded-full ${colorClass} h-[20px] w-[20px]`}
										style={{ backgroundColor: group.colorCode }}
									/>
								</TooltipTrigger>
								<TooltipContent>
									{getGroupName({ t, groupName: group.name })}
								</TooltipContent>
							</Tooltip>
						);
					})}
				</div>
			</GridColumn>
			<GridColumn>
				<Button
					ariaLabel={t("Remove user")}
					onClick={(e) => {
						e.stopPropagation();
						onDeleteUser(user);
					}}
					variant="ghost"
					icon={<IconTrash />}
				/>
			</GridColumn>
		</GridRow>
	);
};
