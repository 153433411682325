import type { User, UserApiResponseType } from "./Types/UserTypes";

export const SUPERADMIN = "SuperAdmin";
export const COMPANYADMIN = "CompanyAdmin";
export const ROLE_PLANNING_ADMIN = "PlanningAdmin";

export const mapApiUserToUser = (user: UserApiResponseType): User => {
	return {
		name: user.name,
		email: user.email,
		accessGroups: user.accessGroups.map((accessGroup) => {
			const legalEntities: string[] = [];
			if (accessGroup.settings.legalEntities) {
				try {
					const json = JSON.parse(accessGroup.settings.legalEntities);
					if (Array.isArray(json)) {
						legalEntities.push(...json);
					}
				} catch {
					//
				}
			}
			return {
				groupId: accessGroup.groupId,
				name: accessGroup.name,
				companyId: accessGroup.companyId,
				description: accessGroup.settings.description,
				colorCode: accessGroup.settings.colorCode,
				legalEntities,
			};
		}),
		companyId: user.companyIds,
		superAdmin: user.superAdmin,
		accessGroupsObjects: user.accessGroupsObjects,
	};
};
