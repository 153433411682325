import { useTranslation } from "react-i18next";
import type { AccessGroup } from "../../common/service/Types/AccessGroup";
import {
	makeArrayLengthSort,
	makeStringSort,
	useSort,
} from "../../common/utils/hooks/useSort";
import { AccessGroupTableRow } from "./AccessGroupTableRow";
import { useState } from "react";
import {
	useDeleteAccessGroupMutation,
	useGetAccessGroupsQuery,
} from "../../common/redux/api/exopenApi";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { AddAccessGroupModal } from "./AddAccessGroupModal";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import { ConfirmDialog } from "../../common/components/atoms/dialog/ConfirmDialog.tsx";
import { TableContainer } from "src/features/planning/components/TableContainer.tsx";
import { GridContainer } from "src/features/planning/scenario/common/grid/GridContainer.tsx";
import {
	GridHeader,
	GridHeaderLabel,
	GridHeaderLabelWithSortingButton,
} from "src/features/planning/scenario/common/grid/GridHeader.tsx";
import { useCurrentCompanyDomainId } from "src/common/company-domain/useCurrentCompanyDomain.ts";

export const AccessGroupTable = () => {
	const { t } = useTranslation();
	const { companyDomainId } = useCurrentCompanyDomainId();
	const { data: accessGroups, isLoading: isLoadingAccessGroups } =
		useGetAccessGroupsQuery(companyDomainId);
	const groupById: Record<number, AccessGroup> = {};
	for (const group of accessGroups ?? []) {
		groupById[group.groupId] = group;
	}
	const { items: sortedGroups, sortColumnProps } = useSort({
		keys: ["byName", "byUsers"],
		defaultKey: "byName",
		defaultOrder: "asc",
		rows: accessGroups ?? [],
		columns: {
			byName: {
				sort: makeStringSort("name"),
			},
			byUsers: {
				sort: makeArrayLengthSort("userEmails"),
			},
		},
	});
	const [groupToEdit, setGroupToEdit] = useState<number>();
	const [groupToDelete, setGroupToDelete] = useState<number>();
	const [deleteAccessGroup, { isLoading: isDeletingAccessGroup }] =
		useDeleteAccessGroupMutation();

	if (isLoadingAccessGroups) {
		return <LoadingState />;
	}

	if (!accessGroups) {
		return <MissingDataState />;
	}

	return (
		<>
			<TableContainer className="bg-white">
				<GridContainer
					templateColumns="auto auto auto auto min-content"
					aria-label={t("Groups")}
					role="table"
				>
					<GridHeaderLabelWithSortingButton
						sortingProps={sortColumnProps.byName}
					>
						{t("Group name")}
					</GridHeaderLabelWithSortingButton>
					<GridHeaderLabel>{t("Description")}</GridHeaderLabel>
					<GridHeaderLabelWithSortingButton
						sortingProps={sortColumnProps.byUsers}
					>
						{t("Number of users")}
					</GridHeaderLabelWithSortingButton>
					<GridHeaderLabel>{t("Colour")}</GridHeaderLabel>
					<GridHeader />
					{sortedGroups.map((accessGroup) => {
						return (
							<AccessGroupTableRow
								accessGroup={accessGroup}
								key={accessGroup.groupId}
								onDeleteGroup={(groupId) => {
									setGroupToDelete(groupId);
								}}
								onEditGroup={(groupId) => {
									setGroupToEdit(groupId);
								}}
							/>
						);
					})}
				</GridContainer>
			</TableContainer>
			<AddAccessGroupModal
				accessGroup={groupToEdit ? groupById[groupToEdit] : undefined}
				modalOpen={groupToEdit !== undefined}
				handleClose={() => {
					setGroupToEdit(undefined);
				}}
				companyId={companyDomainId}
			/>
			<ConfirmDialog
				open={groupToDelete !== undefined}
				onClose={() => {
					setGroupToDelete(undefined);
				}}
				title={t("Remove group")}
				description={t("Do you want to delete the group?")}
				onConfirm={async () => {
					const result = await deleteAccessGroup({
						companyId: companyDomainId,
						groupId: groupToDelete!,
					});
					if ("error" in result) {
						showErrorNotification({ message: t("Failed to delete the group") });
					} else {
						showSuccessNotification({ message: t("The group was deleted") });
						setGroupToDelete(undefined);
					}
				}}
				confirmButtonLabel={t("Remove group")}
				isLoading={isDeletingAccessGroup}
				destructive
			/>
		</>
	);
};
