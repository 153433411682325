import { clsx } from "clsx";
import type { ComponentPropsWithoutRef } from "react";

interface ValidateHintProps extends ComponentPropsWithoutRef<"div"> {
	error?: boolean;
}

export const ValidateHint = ({
	error,
	children,
	className,
	...props
}: ValidateHintProps) => {
	return (
		<div
			className={clsx(
				"text-sm mt-[6px]",
				error ? "text-error-500" : "text-gray-500",
				className,
			)}
			{...props}
		>
			{children}
		</div>
	);
};
