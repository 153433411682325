import tfsofficeSquare from "./logos/24sevenoffice.webp?url";
import businessCentralSquare from "./logos/business-central.webp?url";
import exopenSquare from "./logos/exopen.webp?url";
import forecastProSquare from "./logos/forecast-pro.webp?url";
import fortnoxSquare from "./logos/fortnox.webp?url";
import kleerSquare from "./logos/kleer.webp?url";
import klovernSquare from "./logos/klovern.webp?url";
import mediusSquare from "./logos/medius.webp?url";
import mergeSquare from "./logos/merge.webp?url";
import nextTechSquare from "./logos/next-tech.webp?url";
import sieSquare from "./logos/sie.webp?url";
import skatteverketSquare from "./logos/skatteverket.webp?url";
import unit4Square from "./logos/unit4.webp?url";
import vismaSquare from "./logos/visma.webp?url";
import vitecSquare from "./logos/vitec.webp?url";
import xeroSquare from "./logos/xero.webp?url";
import xledgerSquare from "./logos/xledger.webp?url";
import defaultSquare from "./logos/_default.webp?url";
import type { SourceSystem } from "src/features/connector/sourceSystems";

type SourceSystemMetadata = { name: string; logoUrl: string };

const SOURCE_SYSTEMS_METADATA: Record<
	Lowercase<SourceSystem>,
	SourceSystemMetadata
> = {
	forecastpro: { logoUrl: forecastProSquare, name: "Forecast Pro" },
	businesscentral: { logoUrl: businessCentralSquare, name: "Business Central" },
	business_central: {
		logoUrl: businessCentralSquare,
		name: "Business Central",
	},
	exopendatamodelapi: { logoUrl: exopenSquare, name: "Exopen" },
	demo: { logoUrl: exopenSquare, name: "Exopen" },
	exopenplanning: { logoUrl: exopenSquare, name: "Exopen Planning" },
	fortnox: { logoUrl: fortnoxSquare, name: "Fortnox" },
	pe_accounting: { logoUrl: kleerSquare, name: "Kleer" },
	sie: { logoUrl: sieSquare, name: "SIE" },
	skatteverket: { logoUrl: skatteverketSquare, name: "Skatteverket" },
	tfsoffice: { logoUrl: tfsofficeSquare, name: "24SevenOffice" },
	ubw: { logoUrl: unit4Square, name: "Unit4" },
	vismanet: { logoUrl: vismaSquare, name: "Visma" },
	visma_control_edge: { logoUrl: vismaSquare, name: "Visma" },
	vitec_cloud: { logoUrl: vitecSquare, name: "Vitec Ekonomi" },
	vitec: { logoUrl: vitecSquare, name: "Vitec Ekonomi" },
	vitec_hyra: { logoUrl: vitecSquare, name: "Vitec Hyra" },
	vitecpp7: { logoUrl: vitecSquare, name: "VitecPP7" },
	xero: { logoUrl: xeroSquare, name: "Xero" },
	xledger: { logoUrl: xledgerSquare, name: "Xledger" },
	klovern: { logoUrl: klovernSquare, name: "Klövern" },
	medius_go: { logoUrl: mediusSquare, name: "MediusGo " },
	mergecrm: { logoUrl: mergeSquare, name: "Merge" },
	merge_accounting: { logoUrl: mergeSquare, name: "Merge" },
	next_tech: { logoUrl: nextTechSquare, name: "Next" },
};

export const getSourceSystemMetadata = (
	sourceSystem: SourceSystem,
): SourceSystemMetadata => {
	const systemName = sourceSystem.toLowerCase() as Lowercase<
		typeof sourceSystem
	>;
	const metadata = SOURCE_SYSTEMS_METADATA[systemName];
	if (!metadata) {
		return { name: sourceSystem, logoUrl: defaultSquare };
	}
	return metadata;
};
