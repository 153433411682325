import { QueriesResult } from "components/queryResult/QueriesResult";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router";
import { useCurrentCompanyDomainId } from "src/common/company-domain/useCurrentCompanyDomain";
import { NotConnectedMergeAccountingDataSourcesTable } from "./NotConnectedMergeAccountingDataSourcesTable";
import { BreadcrumbsLayout } from "src/common/components/layout/BreadcrumbsLayout";
import { IconPlugConnected } from "@tabler/icons-react";
import { Button } from "components/button";
import { AddMergeAccountingConnectorModal } from "./AddMergeAccountingConnectorModal";
import { useGetMergeAccountingSystemsQuery } from "src/common/service/fusionHub/integrations/mergeAccountingApi";

export const MergeAccountingConnectorsPage = () => {
	const { t } = useTranslation();

	const { companyDomainId } = useCurrentCompanyDomainId();
	const { system: systemInRoute } = useParams();

	return (
		<BreadcrumbsLayout
			breadcrumbs={[
				{
					icon: <IconPlugConnected />,
				},
				{ name: t("Data sources"), path: "./../.." },
				{ name: t("Configure data source") },
			]}
		>
			<QueriesResult
				queries={{
					mergeAccountingSystems: useGetMergeAccountingSystemsQuery(),
				}}
				render={({ mergeAccountingSystems: { systems } }) => {
					const system = systems.find(
						(system) => system.slug === systemInRoute,
					);
					if (!system) {
						return (
							<Navigate
								to={`/company-domains/${companyDomainId}/data-sources`}
							/>
						);
					}

					return (
						<div className="mx-auto mt-8 max-w-screen-lg">
							<NotConnectedMergeAccountingDataSourcesTable system={system} />

							<div className="mt-8 flex justify-end">
								<AddMergeAccountingConnectorModal
									logo={system.logo}
									name={system.name}
									slug={system.slug}
									key={system.logo}
								>
									<Button>
										{t("Connect to another {{system}} account", {
											system: system.name,
										})}
									</Button>
								</AddMergeAccountingConnectorModal>
							</div>
						</div>
					);
				}}
			/>
		</BreadcrumbsLayout>
	);
};
