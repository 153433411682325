import {
	IconHome,
	IconBuildingSkyscraper,
	IconHierarchy3,
	IconSettings,
	IconAlien,
	IconFileInvoice,
	IconDatabaseCog,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { NavItem } from "./NavItem";
import { NavItemGroup } from "./NavItemGroup";
import { NavSubItem } from "./NavSubItem";
import PlanningIcon from "../../assets/images/icons/planning.svg?react";
import CalculatorIcon from "../../assets/images/icons/calculator.svg?react";
import ConsolidationIcon from "../../assets/images/icons/consolidation.svg?react";
import DataLayerIcon from "../../assets/images/icons/datalayer.svg?react";
import { useUser } from "../../common/context/userContextUtils";
import { CompanySelect } from "./CompanySelect.js";
import { LegalEntitySelect } from "./LegalEntitySelect.js";
import { useLegalEntityHierarchy } from "../../common/legal-entities/useLegalEntityHierarchy.js";
import { Divider } from "../../common/components/atoms/divider/Divider.js";
import { COMPANYADMIN } from "src/common/service/users.js";
import {
	useLoadedCompanyDomain,
	useLoadedLegalEntities,
} from "src/routes/routeDataHooks.js";
import { useCurrentLegalEntity } from "src/common/legal-entities/useCurrentLegalEntity.js";
import { QuickUpdatePopover } from "components/popoverMenu/QuickUpdatePopover.js";

interface CompanySidebarPartProps {
	sidebarOpen: boolean;
}

export const CompanySidebarPart = ({
	sidebarOpen,
}: CompanySidebarPartProps) => {
	const companyDomain = useLoadedCompanyDomain();
	const { legalEntity } = useCurrentLegalEntity();
	const legalEntities = useLoadedLegalEntities();

	const companyId = `${companyDomain.company.id}`;

	const user = useUser();

	const { t } = useTranslation();
	const { hasAnyCorporateGroup } = useLegalEntityHierarchy();
	const hasCompanyAdmin = user.hasPermissions(companyId, [COMPANYADMIN]);

	const accessToSieUpload =
		hasCompanyAdmin || user.hasPermissions(companyId, ["DataSieUpload"]);

	const accessToDataLayer =
		companyDomain.hasDataLayer &&
		(hasCompanyAdmin || user.hasPermissions(companyId, ["DataLayer"]));

	const productLinks: JSX.Element[] = [];
	if (accessToDataLayer)
		productLinks.push(
			<NavItem
				key="data layer"
				to={`/company-domains/${companyId}/datalayer`}
				label="Data Layer"
				icon={<DataLayerIcon />}
			/>,
		);

	if (companyDomain.ewr?.url)
		productLinks.push(
			<NavItem
				key="report"
				to={companyDomain.ewr.url}
				label="Report"
				icon={<CalculatorIcon />}
				reloadDocument
			/>,
			<NavItem
				key="consolidation"
				to={companyDomain.ewr.url}
				label="Consolidation"
				icon={<ConsolidationIcon />}
				reloadDocument
			/>,
		);

	return (
		<div className="grow overflow-y-auto">
			{sidebarOpen && (
				<div className="mx-4 mb-1">
					<Divider label={t("Company domain")} className="my-1" />
					<CompanySelect />
				</div>
			)}
			<nav>
				<div className="mx-4 mb-4 space-y-1">
					<NavItem
						to={`/company-domains/${companyId}`}
						label="Start"
						icon={<IconHome />}
						end
					/>
					{productLinks}
					<NavItem
						to={`/company-domains/${companyId}/planning`}
						label="Planning"
						icon={<PlanningIcon />}
					/>

					{hasCompanyAdmin && hasAnyCorporateGroup() && (
						<NavItem
							label={t("Group structure")}
							to={`/company-domains/${companyId}/legal-entities`}
							icon={<IconBuildingSkyscraper />}
						/>
					)}
					{(hasCompanyAdmin || accessToSieUpload) && (
						<QuickUpdatePopover
							update={t(
								"The upload of SIE files has been moved to the data sources page.",
							)}
							placement="right"
							id="data-sources-sidebar"
						>
							<NavItem
								label={t("Data sources")}
								to={`/company-domains/${companyId}/data-sources`}
								icon={<IconDatabaseCog />}
							/>
						</QuickUpdatePopover>
					)}
					{hasCompanyAdmin && (
						<NavItem
							to={`/company-domains/${companyId}/settings`}
							label={t("Settings")}
							icon={<IconSettings />}
						/>
					)}
				</div>

				{legalEntities && legalEntities.length > 0 && (
					<>
						{sidebarOpen && (
							<div className="mx-4 mb-1">
								<Divider label={t("Legal entity")} className="my-1" />
								<LegalEntitySelect />
							</div>
						)}
						<div className="mx-4 space-y-1">
							{hasCompanyAdmin && legalEntity !== null && (
								<NavItemGroup
									label={t("Data perspective")}
									icon={<IconHierarchy3 />}
								>
									<NavSubItem
										label={t("Account")}
										to={`/${companyId}/administration/hierarchy/account/hierarchy`}
									/>
									<NavSubItem
										label={t("Cost center")}
										to={`/${companyId}/administration/hierarchy/costcenter`}
									/>
									<NavSubItem
										label={t("Customer")}
										to={`/${companyId}/administration/hierarchy/customer`}
									/>
									<NavSubItem
										label={t("Supplier")}
										to={`/${companyId}/administration/hierarchy/supplier`}
									/>
									<NavSubItem
										label={t("Project")}
										to={`/${companyId}/administration/hierarchy/project`}
									/>
									<NavSubItem
										label={t("Product")}
										to={`/${companyId}/administration/hierarchy/product`}
									/>
								</NavItemGroup>
							)}
							{legalEntity && (
								<NavItemGroup label={t("Invoices")} icon={<IconFileInvoice />}>
									<NavSubItem
										label={t("Customer Invoices")}
										to={`/company-domains/${companyId}/legal-entities/${legalEntity.id}/customer-invoices`}
									/>
									<NavSubItem
										label={t("Supplier Invoices")}
										to={`/company-domains/${companyId}/legal-entities/${legalEntity.id}/supplier-invoices`}
									/>
								</NavItemGroup>
							)}
						</div>
					</>
				)}
				<div className="mx-4">
					{user.userIsSuperAdmin() && (
						<NavItemGroup label={t("Internal")} icon={<IconAlien />}>
							<NavSubItem
								to={`/company-domains/${companyId}/internal/dev-tools`}
								label="Utvecklingsverktyg"
							/>
							<NavSubItem
								to={`/company-domains/${companyId}/internal/reports`}
								label={t("EWR reports")}
							/>
							<NavSubItem
								to={`/company-domains/${companyId}/internal/users`}
								label={t("Users")}
							/>
							<NavSubItem
								to={`/company-domains/${companyId}/internal/planning`}
								label="Planning"
							/>
						</NavItemGroup>
					)}
				</div>
			</nav>
		</div>
	);
};
