export interface Duration {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	milliseconds: number;
}

export function getDuration(date1: Date, date2: Date): Duration {
	const diffMs = Math.abs(date2.getTime() - date1.getTime());

	const days = Math.floor(diffMs / (24 * 60 * 60 * 1000));
	const hours = Math.floor((diffMs % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
	const minutes = Math.floor((diffMs % (60 * 60 * 1000)) / (60 * 1000));
	const seconds = Math.floor((diffMs % (60 * 1000)) / 1000);
	const milliseconds = diffMs > 1000 ? 0 : diffMs % 1000;

	return {
		days,
		hours,
		minutes,
		seconds,
		milliseconds,
	};
}

interface FormatOptions {
	// If true, only includes non-zero units
	skipZero?: boolean;
	// If true, uses short unit names (d, h, m, s, ms)
	shortUnits?: boolean;
	// Maximum number of units to display (from largest to smallest)
	maxUnits?: number;
	// Separator between units (default: ' ')
	separator?: string;
}

export function formatDuration(
	duration: Duration,
	options: FormatOptions = {},
): string {
	const {
		skipZero = false,
		shortUnits = false,
		maxUnits = 5,
		separator = " ",
	} = options;

	const units = [
		{ value: duration.days, long: "days", short: "d" },
		{ value: duration.hours, long: "hours", short: "h" },
		{ value: duration.minutes, long: "minutes", short: "m" },
		{ value: duration.seconds, long: "seconds", short: "s" },
		{ value: duration.milliseconds, long: "milliseconds", short: "ms" },
	];

	// Filter out zero values if skipZero is true
	let filteredUnits = skipZero
		? units.filter((unit) => unit.value !== 0)
		: units;

	// Limit to maxUnits
	filteredUnits = filteredUnits.slice(0, maxUnits);

	// Handle empty result (all zeros and skipZero is true)
	if (filteredUnits.length === 0) {
		return `0${shortUnits ? "s" : " seconds"}`;
	}

	// Format each unit
	const formattedUnits = filteredUnits.map((unit) => {
		const unitName = shortUnits
			? unit.short
			: unit.value === 1
				? unit.long.slice(0, -1)
				: unit.long;
		return `${unit.value}${shortUnits ? "" : " "}${unitName}`;
	});

	return formattedUnits.join(separator);
}
