import { useEffect } from "react";
import { useParams } from "react-router";
import { useCompanyDomains } from "src/common/company-domain/useCompanyDomains.ts";
import { isDefined } from "src/common/utils/filters/isDefined.ts";

const siteName = "Exopen";
const SEPARATOR = " · ";

export const useDocumentTitle = (
	title?: string | (string | undefined)[] | undefined,
) => {
	const { companyDomainId } = useParams();
	const { getById } = useCompanyDomains();
	const companyDomain = companyDomainId ? getById(companyDomainId) : null;

	useEffect(() => {
		if (title === undefined) {
			return;
		}

		if (title === "") {
			document.title = companyDomain?.name ?? siteName;
		} else {
			document.title = [title, companyDomain?.name ?? siteName]
				.flat()
				.filter(isDefined)
				.join(` ${SEPARATOR} `);
		}
	}, [title, companyDomain?.name]);
};
