import { useTranslation } from "react-i18next";
import ExopenPlanningIcon from "./product-icons/planning.svg?react";
import ExopenReportIcon from "./product-icons/report.svg?react";
import ExopenConsolidationIcon from "./product-icons/consolidation.svg?react";
import DataLayerIcon from "../../assets/images/icons/datalayer.svg?react";
import { clsx } from "clsx";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle.ts";
import { useUser } from "../../common/context/userContextUtils.ts";
import { useLoadedCompanyDomain } from "src/routes/routeDataHooks.ts";
import { Link } from "react-router";
import background from "./page-background-cross.svg";
import shape1 from "./heading-shape1.svg";
import shape2 from "./heading-shape2.svg";
import shape3 from "./heading-shape3.svg";

export const CompanyDashboard = () => {
	const { t } = useTranslation();
	const companyDomain = useLoadedCompanyDomain();
	const { user } = useUser();
	useDocumentTitle("Start");

	const products = [
		{
			color: "bg-[#ff9533]",
			name: "Data Layer",
			path: `/company-domains/${companyDomain.company.id}/datalayer`,
			description: t("Exopens generalized data layer"),
			icon: (
				<DataLayerIcon className="size-7 block shrink-0 ml-2 fill-[#ff9533]" />
			),
			style: {
				backgroundImage: `url("${shape1}")`,
			},
		},
		{
			color: "bg-[#3399cc]",
			name: "Planning",
			path: `/company-domains/${companyDomain.company.id}/planning`,
			description: t("Efficient and user-friendly budget and forecast"),
			icon: <ExopenPlanningIcon className="size-7 block shrink-0 ml-2" />,
			style: {
				backgroundImage: `url("${shape2}")`,
			},
		},
		{
			color: "bg-[#00cc33]",
			name: "Report",
			path: companyDomain.ewr?.url ?? "",
			description: t("Customized reporting in Excel/Web"),
			icon: <ExopenReportIcon className="size-7 block shrink-0 ml-2" />,
			style: {
				backgroundImage: `url("${shape3}")`,
			},
		},
		{
			color: "bg-[#003300]",
			name: "Consolidation",
			path: companyDomain.ewr?.url ?? "",
			description: t("A comprehensive consolidation tool"),
			icon: <ExopenConsolidationIcon className="size-7 block shrink-0 ml-2" />,
			style: {
				backgroundImage: `url("${shape2}")`,
			},
		},
	].filter((product) => {
		return product.path;
	});

	return (
		<div
			className="p-4 lg:p-10 bg-no-repeat bg-right-top bg-[length:600px_600px]"
			style={{
				backgroundImage: `url("${background}")`,
			}}
		>
			<div className={clsx("mb-4 font-semibold text-xl")}>
				<span className="text-green-500">{t("Welcome")}</span> {user.name}
			</div>
			<div className="font-bold text-3xl lg:text-4xl mb-6 text-gray-900">
				{t("We take your financial planning and analysis to the next level!")}
			</div>

			<p className="text-sm lg:text-base mb-8 font-medium max-w-[70ch]">
				{t(
					"Make your everyday life more efficient with Exopen's easy-to-use products that give you insightful analysis in Power BI, agile budgeting, consolidation and customized reporting. You get everything in Exopen Cloud, with ready-made integrations and with guaranteed data quality.",
				)}
			</p>

			<section className="flex gap-4 flex-wrap">
				{products.map((product) => {
					const card = (
						<>
							<div
								className={clsx(
									"h-[114px] pl-4 pt-9 bg-right-top bg-no-repeat",
									product.color,
								)}
								style={product.style}
							>
								<div className="text-lg leading-5 text-white font-semibold">
									Exopen
								</div>
								<div className="text-2xl leading-7 text-white font-bold">
									{product.name}
								</div>
							</div>
							<div className="bg-white flex text-gray-900 p-4">
								<div className="grow text-sm font-medium">
									{product.description}
								</div>
								{product.icon}
							</div>
						</>
					);

					return (
						<Link
							key={product.name}
							className="w-full lg:w-[200px] rounded-tr-2xl rounded-bl-2xl overflow-hidden shadow hover:shadow-lg transition-shadow"
							to={product.path}
							viewTransition
							{...(product.path.startsWith("https") && {
								target: "_blank",
								rel: "noreferrer",
							})}
						>
							{card}
						</Link>
					);
				})}
			</section>
		</div>
	);
};
