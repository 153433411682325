import { useTranslation } from "react-i18next";
import { Input } from "../../common/components/atoms/input/Input.tsx";
import { Label } from "../../common/components/atoms/label/Label.tsx";
import { DeleteCompanyDomainDialog } from "./DeleteCompanyDomainDialog.tsx";
import { Card } from "../planning/components/Card.tsx";
import { Text } from "../../common/components/atoms/typography/Text.tsx";
import { Button } from "../../common/components/atoms/button/Button.tsx";
import { useLoadedCompanyDomain } from "src/routes/routeDataHooks.ts";

export const CompanyDomainSettingsPage = () => {
	const { t } = useTranslation();
	const companyDomain = useLoadedCompanyDomain();

	return (
		<div className="space-y-4">
			<Card>
				<div className="p-4">
					<Text weight="bold" size="lg" className="mb-2">
						{t("General")}
					</Text>
					<form>
						<Label>{t("Company domain name")}</Label>
						<Input disabled value={companyDomain.company.name} />
						<div className="flex justify-end mt-4">
							<Button type="submit" disabled>
								{t("Save")}
							</Button>
						</div>
					</form>
				</div>
			</Card>

			<Card>
				<div className="p-4">
					<Text weight="bold" size="lg" className="mb-2">
						{t("Delete company domain")}
					</Text>
					<Text className="mb-2" size="sm">
						{t(
							"Permanently remove your company domain and all of its contents from Exopen. This action is not reversible — please continue with caution.",
						)}
					</Text>
					<DeleteCompanyDomainDialog />
				</div>
			</Card>
		</div>
	);
};
