import {
	useMergeRefs,
	useTransitionStyles,
	FloatingPortal,
	FloatingFocusManager,
	FloatingArrow,
} from "@floating-ui/react";
import {
	forwardRef,
	type ComponentProps,
	type ReactNode,
	type MutableRefObject,
	useRef,
} from "react";
import { usePopoverMenuContext } from "./usePopoverMenuContext.js";
import { clsx } from "clsx";
import { maybe } from "../../../utils/maybe.js";

const ARROW_WIDTH = 14;
const ARROW_HEIGHT = 7;

type PopoverContentProps = Omit<ComponentProps<"div">, "children"> & {
	children:
		| ReactNode
		| ((options: {
				close: () => void;
				initialElementRef: MutableRefObject<HTMLElement | null>;
		  }) => JSX.Element);
};

export const PopoverContent = forwardRef<HTMLElement, PopoverContentProps>(
	function PopoverContent({ children, style, className, ...rest }, propRef) {
		const {
			context: floatingContext,
			arrowRef,
			floatingStyles,
			middlewareData,
			getFloatingProps,
			setOpen,
			withArrow,
			disableInteractions,
			...context
		} = usePopoverMenuContext();
		const ref = useMergeRefs([context.refs.setFloating, propRef]);

		const arrowX = middlewareData.arrow?.x ?? 0;
		const arrowY = middlewareData.arrow?.y ?? 0;
		const transformX = arrowX + ARROW_WIDTH / 2;
		const transformY = arrowY + ARROW_HEIGHT;

		const { styles: transitionStyles, isMounted } = useTransitionStyles(
			floatingContext,
			{
				duration: 100,
				initial: {
					opacity: 0,
					transform: "scale(0.95)",
				},
				common: ({ side }) => ({
					transformOrigin: {
						top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
						bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
						left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
						right: `${-ARROW_HEIGHT}px ${transformY}px`,
					}[side],
					height: "100%",
				}),
			},
		);

		const initialElementRef = useRef<HTMLElement | null>(null);

		if (!isMounted) return null;

		const floatingProps = getFloatingProps({
			className: clsx("z-10 focus:outline-none", className),
			...rest,
		});

		return (
			<FloatingPortal>
				<FloatingFocusManager
					context={floatingContext}
					{...maybe(typeof children === "function", {
						initialFocus: initialElementRef,
					})}
					disabled={disableInteractions}
				>
					<div
						ref={ref}
						style={{
							...floatingStyles,
							...style,
							zIndex: style?.zIndex ?? 1400,
						}}
						{...floatingProps}
					>
						<div
							style={transitionStyles}
							aria-labelledby={context.labelId}
							aria-describedby={context.descriptionId}
						>
							{typeof children === "function"
								? children({
										close() {
											setOpen(false);
										},
										initialElementRef,
									})
								: children}
							{withArrow && (
								<FloatingArrow
									ref={arrowRef}
									context={floatingContext}
									width={ARROW_WIDTH}
									height={ARROW_HEIGHT}
									strokeWidth={0.5}
									tipRadius={1}
									style={{
										transform: "translateY(-1px)",
									}}
									className="fill-white [&>path:first-of-type]:stroke-grey-300"
								/>
							)}
						</div>
					</div>
				</FloatingFocusManager>
			</FloatingPortal>
		);
	},
);
