import { clsx } from "clsx";
import { type ComponentProps, forwardRef } from "react";

type AvatarSize = "xs" | "sm" | "md" | "lg" | "xl";

const sizes: Record<AvatarSize, number> = {
	xs: 20,
	sm: 32,
	md: 40,
	lg: 48,
	xl: 56,
};

interface AvatarProps extends Omit<ComponentProps<"span">, "style"> {
	size: "xs" | "sm" | "md" | "lg" | "xl";
	src: string;
	alt: string;
}

export const Avatar = forwardRef<HTMLSpanElement, AvatarProps>(function Avatar(
	{ size, className, src, alt, ...props },
	ref,
) {
	const sizeValue = sizes[size];

	return (
		<span
			className={clsx("inline-flex shrink-0", className)}
			style={{ width: sizeValue, height: sizeValue }}
			ref={ref}
			aria-hidden="true"
			{...props}
		>
			<img className="rounded-full" alt={alt} src={src} loading="lazy" />
		</span>
	);
});
