import { useTranslation } from "react-i18next";
import { Text } from "../../../atoms/typography/Text";
import { LoadingState } from "../../../atoms/loadingState/LoadingState";

interface IProps {
	file: File;
	isLoading: boolean;
}

export default function FileDetails({ file, isLoading }: IProps) {
	const { t } = useTranslation();
	const convertToKB = (bytes: number) => {
		return Number(bytes / 1024).toFixed(1);
	};
	return (
		<div className="py-1">
			<Text size="sm" weight="medium">
				{file.name || t("File name is missing")}
			</Text>
			<Text size="sm" weight="regular">{`${convertToKB(file.size)} KB`}</Text>
			{isLoading && <LoadingState />}
		</div>
	);
}
