import { isValidElement } from "react";
import { Navigate, Outlet } from "react-router";
import { useUser } from "../common/context/userContextUtils";
import { useCurrentCompanyDomainId } from "src/common/company-domain/useCurrentCompanyDomain";
import type { Permission } from "src/common/context/useUser";

interface AuthRouteProps {
	Component?: any;
	permissions?: Permission[] | undefined;
	permissionMode?: "all" | "any" | undefined;
}

export const AuthRoute = ({
	Component = Outlet,
	permissions = [],
	permissionMode = "all",
}: AuthRouteProps): JSX.Element => {
	const { hasPermissions } = useUser();
	const { companyDomainId } = useCurrentCompanyDomainId();
	const hasAccess =
		permissions.length > 0
			? hasPermissions(companyDomainId, permissions, permissionMode)
			: true;
	if (hasAccess) {
		if (isValidElement(Component)) {
			return Component;
		}
		return <Component />;
	}
	return <Navigate to="/" />;
};

export const SuperAdminRoute = ({
	Component = Outlet,
}: {
	Component?: any;
}) => {
	const { userIsSuperAdmin } = useUser();
	if (userIsSuperAdmin()) {
		if (isValidElement(Component)) {
			return Component;
		}
		return <Component />;
	}
	return <Navigate to="/" />;
};
