import { createContext, type ReactNode, useMemo } from "react";
import { useCurrentLegalEntity } from "../legal-entities/useCurrentLegalEntity.js";
import { extractLegalEntitiesFromHierarchy } from "../legal-entities/utils/extractLegalEntitiesFromHierarchy.js";
import { getLegalEntityInHierarchy } from "../legal-entities/utils/getLegalEntityInHierarchy.js";
import type { LegalEntity } from "../service/nexus/endpoints/companyDomain/types.js";
import {
	useLoadedCompanyHierarchy,
	useLoadedLegalEntities,
} from "src/routes/routeDataHooks.js";

export interface LegacyLegalEntity {
	groupName: string;
	groupMasterKey: string;
	memberMasterKeys: string[];
}

type LegacyLegalEntitiesContextType = {
	legalEntities: LegacyLegalEntity[];
	legalEntity: LegacyLegalEntity | null;
	legalEntityById: Record<string, LegacyLegalEntity>;
};

export const LegacyLegalEntitiesContext =
	createContext<LegacyLegalEntitiesContextType | null>(null);
LegacyLegalEntitiesContext.displayName = "LegacyLegalEntitiesContext";

function useProvideLegalEntities() {
	const legalEntities = useLoadedLegalEntities();
	const legalEntityHierarchy = useLoadedCompanyHierarchy();

	const state = useMemo(() => {
		const extractedLegalEntities = legalEntities.map((legalEntity) => {
			let childLegalEntities: LegalEntity[] = [];

			if (legalEntityHierarchy.hierarchy) {
				const partOfHierarchy = getLegalEntityInHierarchy(
					legalEntity,
					legalEntityHierarchy.hierarchy,
				);

				if (partOfHierarchy) {
					childLegalEntities =
						extractLegalEntitiesFromHierarchy(partOfHierarchy);
				}
			}

			return {
				groupName: legalEntity.name,
				groupMasterKey: legalEntity.legalEntityMasterKey,
				memberMasterKeys: childLegalEntities.map(
					(legalEntity) => legalEntity.legalEntityMasterKey,
				),
			};
		});

		return {
			legalEntities: extractedLegalEntities,
			legalEntityById: extractedLegalEntities.reduce<
				Record<string, LegacyLegalEntity>
			>((map, item) => {
				map[item.groupMasterKey] = item;
				return map;
			}, {}),
		};
	}, [legalEntityHierarchy, legalEntities]);

	const { legalEntity: currentLegalEntity } = useCurrentLegalEntity();

	return useMemo(() => {
		const currentLegacyLegalEntity = currentLegalEntity
			? state.legalEntityById[currentLegalEntity.legalEntityMasterKey]
			: null;

		return {
			legalEntities: state.legalEntities,
			legalEntity: currentLegacyLegalEntity,
			legalEntityById: state.legalEntityById,
		};
	}, [currentLegalEntity, state.legalEntityById, state.legalEntities]);
}

interface Props {
	children?: ReactNode | undefined;
}

export function ProvideLegacyLegalEntities({ children }: Props) {
	const legalEntities = useProvideLegalEntities();

	return (
		<LegacyLegalEntitiesContext.Provider value={legalEntities}>
			{children}
		</LegacyLegalEntitiesContext.Provider>
	);
}
