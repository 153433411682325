import { BlockBlobClient } from "@azure/storage-blob";
import { showErrorNotification } from "components/notifications/events";
import { type ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentCompanyDomain } from "src/common/company-domain/useCurrentCompanyDomain";
import { useLazyGetSieFileUploadUrlQuery } from "src/common/service/fusionHub/integrations/sieApi";

export const SieUploader = ({
	onUpload,
	render,
}: {
	onUpload?:
		| ((data: { sourceId: string; fileName: string }) => void)
		| undefined;
	render: (options: {
		uploadFile: (file: File) => Promise<void>;
		isUploading: boolean;
	}) => ReactNode;
}) => {
	const { t } = useTranslation();

	const { companyDomain } = useCurrentCompanyDomain();

	const [isUploading, setIsUploading] = useState(false);
	const [getUploadUrl] = useLazyGetSieFileUploadUrlQuery();

	const handleFileChange = async (file: File) => {
		setIsUploading(true);
		try {
			const result = await getUploadUrl({
				companyDomainId: companyDomain.id,
			});
			if (!result.isSuccess) {
				showErrorNotification({
					message: t("Failed to upload file"),
				});

				return;
			}
			const { uploadUrl, sourceId } = result.data;
			const blobClient = new BlockBlobClient(uploadUrl);

			await blobClient.uploadData(file);

			if (onUpload) {
				onUpload({ sourceId, fileName: file.name });
			}
		} catch (err) {
			showErrorNotification({
				message: t("Something failed..."),
			});
		} finally {
			setIsUploading(false);
		}
	};

	return render({ uploadFile: handleFileChange, isUploading });
};
