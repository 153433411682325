import { IconError404 } from "@tabler/icons-react";
import { Anchor } from "components/anchor/Anchor";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";

export const PageNotFound = () => {
	const { t } = useTranslation();

	return (
		<div className="my-6 grid place-content-center gap-4 h-screen">
			<div className="flex flex-col items-center">
				<IconError404 size={68} stroke={1.5} className="text-purple-500 mb-2" />
				<div className="mb-4">{t("Sorry, we couldn't find that page")}</div>

				<Anchor component={Link} to="/">
					{t("Dashboard")}
				</Anchor>
			</div>
		</div>
	);
};
