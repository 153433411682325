import { IconCloudUpload } from "@tabler/icons-react";
import { useState } from "react";
import { type Accept, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Button } from "../../atoms/button/Button";
import FilePreview from "./preview/FilePreview";
import { Text } from "../../atoms/typography/Text";

interface FileUploaderProps {
	accept: Accept;
	isLoading: boolean;
	maxFiles: number;
	onDraggedFiles?: (files: File[]) => void;
	dropText?: string;
}

export default function FileUploader({
	isLoading,
	accept,
	dropText,
	maxFiles,
	onDraggedFiles,
}: FileUploaderProps) {
	const { t } = useTranslation();
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

	const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
		onDropAccepted: (files) => {
			setUploadedFiles(files);
			onDraggedFiles?.(files);
		},
		accept,
		noClick: true,
		maxFiles,
	});

	const fileRejectionItems = fileRejections.map(({ file, errors }) => {
		return (
			<li key={file.name}>
				{file.name} - {file.size} bytes
				<div style={{ color: "red", margin: "0px 10px 15px 20px" }}>
					{errors.map((e) => {
						return <div key={e.code}>{e.message}</div>;
					})}
				</div>
			</li>
		);
	});

	return (
		<div>
			<div
				{...getRootProps()}
				className="mb-4 rounded-[8px] border border-dashed border-grey-500"
			>
				<div className="flex flex-col items-center justify-center py-9">
					<div className="mb-4">
						<IconCloudUpload className="text-purple-500" size={28} />
					</div>
					<div className="mb-2">
						<Text size="sm" weight="medium">
							{dropText ?? t("Drag and drop files here")}
						</Text>
					</div>
					<div className="mb-2">
						<Text size="sm" weight="medium">
							{t("or")}
						</Text>
					</div>
					<div>
						<Button onClick={open}>{t("Browse files")}</Button>
					</div>
					{fileRejections.length > 0 && (
						<div style={{ margin: "20px" }}>
							<p style={{ marginBottom: "15px" }}>
								{t("The following files could not be uploaded")}
								{": "}
							</p>
							<div>{fileRejectionItems}</div>
						</div>
					)}
				</div>
				<input {...getInputProps()} />
			</div>
			<div>
				{uploadedFiles.map((file) => {
					return (
						<FilePreview
							key={file.lastModified}
							file={file}
							isLoading={isLoading}
						/>
					);
				})}
			</div>
		</div>
	);
}
