class Defer<T> {
	promise: Promise<T>;
	resolve: (value: T | PromiseLike<T>) => void;
	reject: (reason?: any) => void;

	constructor() {
		this.resolve = () => {};
		this.reject = () => {};

		this.promise = new Promise<T>((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;
		});
	}
}

export const getDeferredPromise = <T>() => {
	return new Defer<T>();
};
