import { useTranslation } from "react-i18next";
import { useCompanyDomainsOrThrow } from "../../common/company-domain/useCompanyDomains.js";
import { useCurrentCompanyDomain } from "../../common/company-domain/useCurrentCompanyDomain.js";
import { PopoverTrigger } from "../../common/components/atoms/popoverMenu/PopoverTrigger.js";
import { PopoverContent } from "../../common/components/atoms/popoverMenu/PopoverContent.js";
import { Popover } from "../../common/components/atoms/popoverMenu/Popover.js";
import { Link } from "react-router";
import { Text } from "../../common/components/atoms/typography/Text.js";
import {
	IconArrowNarrowRight,
	IconSelector,
	IconSettings,
} from "@tabler/icons-react";
import { ButtonLink } from "../../common/components/atoms/button/ButtonLink.js";
import { type MutableRefObject, useMemo, useState } from "react";
import { Input } from "../../common/components/atoms/input/Input.js";
import { clsx } from "clsx";

export const CompanySelect = () => {
	const { t } = useTranslation();
	const { companyDomains } = useCompanyDomainsOrThrow();
	const { companyDomain: currentCompanyDomain } = useCurrentCompanyDomain();
	const [filter, setFilter] = useState("");
	const companyList = useMemo(() => {
		const companyList = companyDomains
			.map((companyDomain) => {
				return { id: companyDomain.id, name: companyDomain.name };
			})
			.filter((companyDomain) => {
				return (
					companyDomain.id !== currentCompanyDomain.id &&
					companyDomain.name
						.toLocaleLowerCase()
						.includes(filter.toLocaleLowerCase())
				);
			});
		companyList.sort((a, b) => a.name.localeCompare(b.name));
		return companyList;
	}, [companyDomains, currentCompanyDomain.id, filter]);

	return (
		<>
			{/* <AddCompany
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      /> */}
			<Popover role="menu" withArrow={false}>
				<PopoverTrigger asChild>
					<button
						type="button"
						className="flex w-full items-center gap-2 rounded-lg bg-gray-100 py-2 pl-4 pr-2 text-left hover:bg-gray-200 aria-expanded:bg-gray-200"
					>
						<Text size="sm" className="grow">
							{currentCompanyDomain.name}
						</Text>
						<IconSelector className="text-purple-500" size={20} />
					</button>
				</PopoverTrigger>
				<PopoverContent className="w-96">
					{({ close, initialElementRef }) => {
						return (
							<Popover.ContentContainer>
								<div className="flex items-center gap-2 border-b p-2 pl-4">
									<Text className="grow" weight="medium" size="sm">
										{currentCompanyDomain.name}
									</Text>
									<ButtonLink
										variant="ghost"
										to={`/company-domains/${currentCompanyDomain.id}/settings`}
										icon={<IconSettings />}
										ariaLabel={t("Settings")}
										size="sm"
										onClick={() => {
											close();
										}}
									/>
								</div>
								<div className={clsx(companyList.length > 0 && "border-b")}>
									<Input
										borderless
										type="search"
										placeholder={t("Search company domain")}
										ref={
											initialElementRef as MutableRefObject<HTMLInputElement | null>
										}
										value={filter}
										onChange={(e) => {
											setFilter(e.target.value);
										}}
										clearable
									/>
								</div>
								{companyList.length > 0 && (
									<div className="flex max-h-96 flex-col overflow-auto">
										{companyList.map((company) => {
											return (
												<Link
													key={company.id}
													to={`/${company.id}`}
													className="group flex items-center gap-2 border-b px-3 py-2 text-gray-700 last:border-b-0 hover:bg-gray-100"
													onClick={() => {
														close();
													}}
												>
													<Text className="grow truncate" size="sm">
														{company.name}
													</Text>
													<IconArrowNarrowRight
														className="shrink-0 text-gray-500 opacity-0 group-hover:opacity-100"
														size={20}
													/>
												</Link>
											);
										})}
									</div>
								)}
								{/* <button
                  className="group flex w-full items-center gap-2 bg-gray-50 px-4 py-2 font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  type="button"
                  onClick={() => {
                    setOpen(true);
                    close();
                  }}
                >
                  <IconPlus
                    className="text-gray-500 group-hover:text-gray-700"
                    size={20}
                  />
                  {t("Create Company Domain")}
                </button> */}
							</Popover.ContentContainer>
						);
					}}
				</PopoverContent>
			</Popover>
		</>
	);
};
