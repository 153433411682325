import { useTranslation } from "react-i18next";
import { showErrorNotification } from "../../../../common/components/atoms/notifications/events.js";
import { useState } from "react";
import { Button } from "components/button";
import { Text } from "../../../../common/components/atoms/typography/Text.js";
import { Dialog } from "components/dialog/Dialog";
import { DialogActions } from "components/dialog/DialogActions";
import { DialogContent } from "components/dialog/DialogContent";
import { DialogTitle } from "components/dialog/DialogTitle";
import { QueryResult } from "components/queryResult/QueryResult.js";
import { Select } from "components/select/Select.js";
import { isDefined } from "src/common/utils/filters/isDefined.js";
import { DialogBody } from "components/dialog/DialogBody.js";
import { usePersistedNavigate } from "src/common/persistentRouterFunctions.js";
import {
	type MergeAccountingCompanyInfo,
	type MergeAccountingToken,
	useCreateMergeAccountingIntegrationMutation,
	useGetMergeAccountingTrackingCategoriesQuery,
} from "src/common/service/fusionHub/integrations/mergeAccountingApi.js";
import type { MergeSystem } from "src/common/service/fusionHub/types.js";
import { useUpsertLegalEntityFromFusionhubIntegrationsMutation } from "src/common/service/nexus/nexusApi.js";
import { useCurrentCompanyDomainId } from "src/common/company-domain/useCurrentCompanyDomain.js";

const NO_COST_CENTER = "NO_COST_CENTER" as const;

type Props = {
	token: MergeAccountingToken;
	companyInfo: MergeAccountingCompanyInfo;
	system: MergeSystem;
};

export const CreateMergeAccountingIntegrationModal = ({
	token,
	companyInfo,
	system,
}: Props) => {
	const { t } = useTranslation();
	const { companyDomainId } = useCurrentCompanyDomainId();

	const [trackingCategoryType, setTrackingCategoryType] = useState<
		string | null
	>(NO_COST_CENTER);

	const [isOpen, setIsOpen] = useState(false);

	const [createIntegrationMutation] =
		useCreateMergeAccountingIntegrationMutation();
	const [upsertLegalEntity] =
		useUpsertLegalEntityFromFusionhubIntegrationsMutation();

	const navigate = usePersistedNavigate();

	const createIntegration = async () => {
		if (!trackingCategoryType) {
			return;
		}

		const result = await createIntegrationMutation({
			companyInfoId: companyInfo.id,
			tokenId: token.id,
			costCenterTrackingCategoryType:
				trackingCategoryType === NO_COST_CENTER
					? undefined
					: trackingCategoryType,
		});

		if ("error" in result) {
			showErrorNotification({ message: t("Failed to create integration") });
		} else {
			const upsertResult = await upsertLegalEntity({
				companyDomainId,
				integrationIds: [result.data.integrationId],
			});
			if ("error" in upsertResult) {
				showErrorNotification({ message: t("Failed to add data source") });
			} else {
				navigate("./../..");
			}
		}
	};

	return (
		<>
			{!isOpen && (
				<Button variant="primary" onClick={() => setIsOpen(true)}>
					{t("Connect")}
				</Button>
			)}
			<Dialog open={isOpen} onClose={() => setIsOpen(false)}>
				<DialogContent size="md">
					<QueryResult
						query={useGetMergeAccountingTrackingCategoriesQuery({
							tokenId: token.id,
							mergeCompanyId: companyInfo.id,
						})}
						render={({ trackingCategories }) => {
							const uniqueTrackingCategoryTypes = trackingCategories
								.map((trackingCategory) => trackingCategory.categoryType)
								.filter((value, index, self) => self.indexOf(value) === index)
								.filter(isDefined);
							return (
								<>
									<DialogTitle>
										{t("Create integration for {{legalEntityName}}", {
											legalEntityName: companyInfo.name,
										})}
									</DialogTitle>
									<DialogBody>
										<Text size="sm" color="text-grey-500" className="mt-2">
											{t("Select cost center tracking category")}
										</Text>

										<Select
											className="mb-4"
											ariaLabel={t("Cost center tracking category")}
											placeholder={t("Cost center tracking category")}
											value={trackingCategoryType}
											onChange={(value) => setTrackingCategoryType(value)}
											options={[
												{ value: NO_COST_CENTER, label: t("No cost center") },
												...uniqueTrackingCategoryTypes.map((category) => ({
													value: category,
													label: category,
												})),
											]}
										/>

										<img
											src={system.logo}
											alt={`${system.slug} logotype`}
											width={80}
										/>
									</DialogBody>
									<DialogActions withCancelButton>
										<Button
											variant="primary"
											iconPosition="right"
											onClick={createIntegration}
											disabled={!trackingCategoryType}
										>
											{t("Continue")}
										</Button>
									</DialogActions>
								</>
							);
						}}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};
