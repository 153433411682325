import type { IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	forwardRef,
	useContext,
	type ComponentProps,
	type ReactElement,
} from "react";
import type { NavLink, To } from "react-router";
import { PersistedNavLink } from "../../common/persistentRouterFunctions";
import { LinkItem } from "./LinkItem";
import { SidebarContext } from "./SidebarContext";
import { Tooltip } from "../../common/components/atoms/tooltip/Tooltip.tsx";
import { TooltipTrigger } from "../../common/components/atoms/tooltip/TooltipTrigger.tsx";
import { TooltipContent } from "../../common/components/atoms/tooltip/TooltipContent.tsx";

interface NavItemProps extends ComponentProps<typeof NavLink> {
	icon: ReactElement<IconProps>;
	label: string;
	to: To;
}

export const NavItem = forwardRef<HTMLAnchorElement, NavItemProps>(
	function NavItem({ icon, to, label, className, ...other }, ref) {
		const { open } = useContext(SidebarContext);
		const linkClassName = clsx("block", className);
		if (typeof to === "string" && to.startsWith("http")) {
			const item = (
				<a href={to} className={linkClassName} aria-label={label} ref={ref}>
					<LinkItem
						isActive={false}
						icon={icon}
						label={open ? label : undefined}
					/>
				</a>
			);
			if (open) {
				return item;
			}
			return (
				<Tooltip placement="right">
					<TooltipTrigger asChild>{item}</TooltipTrigger>
					<TooltipContent>{label}</TooltipContent>
				</Tooltip>
			);
		}

		const item = (
			<PersistedNavLink
				to={to}
				className={linkClassName}
				{...other}
				aria-label={!open ? label : undefined}
				viewTransition
				ref={ref}
			>
				{({ isActive }) => {
					return (
						<LinkItem
							isActive={isActive}
							icon={icon}
							label={open ? label : undefined}
						/>
					);
				}}
			</PersistedNavLink>
		);

		if (open) {
			return item;
		}
		return (
			<Tooltip placement="right">
				<TooltipTrigger asChild>{item}</TooltipTrigger>
				<TooltipContent>{label}</TooltipContent>
			</Tooltip>
		);
	},
);
