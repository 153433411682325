import { clsx } from "clsx";
import type { ReactNode, LabelHTMLAttributes, ComponentProps } from "react";
import { Text } from "../typography/Text";
import { SortingArrowButton2 } from "../../../../features/planning/components/SortingArrowButton";
import { PlanningTable2 } from "../../../../features/planning/components/PlanningTable";
import { useTranslation } from "react-i18next";

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
	children?: ReactNode | undefined;
	for?: string;
	optional?: boolean;
}

export const Label = ({
	children,
	className,
	optional,
	...props
}: LabelProps) => {
	const { t } = useTranslation();

	return (
		<Text
			size="sm"
			weight="medium"
			color="text-grey-700"
			className={clsx("inline-block pb-[6px]", className)}
			component="label"
			{...props}
		>
			{children}
			{optional && (
				<span className="pl-2 text-xs text-gray-500">({t("optional")})</span>
			)}
		</Text>
	);
};

interface LabelWithSortingButtonProps {
	labelProps?: ComponentProps<"label">;
	children?: ReactNode | undefined;
	sortingProps: ComponentProps<typeof SortingArrowButton2>;
}

export const LabelWithSortingButton = ({
	labelProps,
	children,
	sortingProps,
}: LabelWithSortingButtonProps) => {
	return (
		<div className="group mb-2 flex items-center gap-x-1">
			<label {...labelProps}>
				<PlanningTable2.ThText>{children}</PlanningTable2.ThText>
			</label>
			<SortingArrowButton2 {...sortingProps} />
		</div>
	);
};
