import type { To } from "react-router";
import { PersistedNavLink } from "../../common/persistentRouterFunctions";
import { IconPoint } from "@tabler/icons-react";
import { LinkItem } from "./LinkItem.tsx";

interface NavSubItemProps {
	label: string;
	to: To;
}

export const NavSubItem = ({ to, label }: NavSubItemProps) => {
	if (typeof to === "string" && to.startsWith("http")) {
		return (
			<a href={to} className="block">
				<LinkItem icon={<IconPoint />} label={label} />
			</a>
		);
	}

	return (
		<PersistedNavLink to={to} className="block" viewTransition>
			{({ isActive }) => {
				return (
					<LinkItem isActive={isActive} icon={<IconPoint />} label={label} />
				);
			}}
		</PersistedNavLink>
	);
};
