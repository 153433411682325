import { useTranslation } from "react-i18next";
import { IconCopy } from "@tabler/icons-react";
import { Input } from "components/input/Input";
import { Button } from "components/button";
import { showSuccessNotification } from "components/notifications/events";
import { Text } from "components/typography/Text";
import { ExopenLogo } from "src/features/sidebar/ExopenLogo";

export const AddFortnoxConnectorExternalRedirect = () => {
	const { t } = useTranslation();

	const url = window.location.href.replace("external", "redirect");

	return (
		<>
			<div className="md:py-44">
				<div className="mx-auto max-w-lg p-6 md:rounded-lg md:bg-white md:shadow">
					<div className="w-24 text-primary-400">
						<ExopenLogo />
					</div>
					<Text size="xl" weight="semibold" className="pb-3 pt-6 text-center">
						{t("Add data source")}
					</Text>

					<Text size="md" className="px-10 pb-6 text-center">
						{t(
							"Copy the link below and send to your contact with an Exopen account",
						)}
					</Text>
					<div className="flex gap-4">
						<Input
							ariaLabel="redirectUrl"
							containerProps={{ className: "grow" }}
							defaultValue={url}
							disabled
						/>

						<Button
							variant="secondaryGray"
							onClick={async () => {
								await navigator.clipboard.writeText(url);
								showSuccessNotification({
									message: t("The link was copied to clipboard"),
								});
							}}
							icon={<IconCopy />}
						>
							{t("Copy link")}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};
