import {
	type PlanComparisonData,
	type DomainPlan,
	type ExportPlanBody,
	type PlanDimension,
	buildPlanRouteUrl,
	type UpdatePlanStatus,
	type DomainPlanTransaction,
	type ModuleRefType,
	type OffsetBasedPaginatedResponse,
} from "@exopengithub/planning-api-shared";
import { dateFromYearMonth } from "components/yearMonthPicker/yearMonthUtils.js";
import { endOfMonth } from "date-fns";
import { formatYYYYMMDD } from "../utils.js";
import { emptyPlanningApi } from "./emptyPlanningApi.js";
import type { YearMonth } from "components/yearMonthPicker/YearMonthPicker.js";
import { dateToPlainDate } from "../../../common/utils/date-utils/dateToPlainYearMonth.js";
import { asyncMap } from "src/common/utils/asyncMap.js";
import type {
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	QueryReturnValue,
} from "@reduxjs/toolkit/query";
import { loadAllPages } from "./utils/loadAllPages.js";

const planApi = emptyPlanningApi.injectEndpoints({
	endpoints: (builder) => ({
		getPlan: builder.query<
			DomainPlan,
			{ planId: string; companyDomainId: string; legalEntityId: string }
		>({
			query: ({ planId, companyDomainId, legalEntityId }) => {
				return buildPlanRouteUrl("", {
					companyDomainId,
					legalEntityId,
					planId,
				});
			},
			providesTags: (result, _error, arg) =>
				result ? [{ type: "Plan", id: arg.planId }, "Plan"] : ["Plan"],
		}),

		createPlan: builder.mutation<
			DomainPlan,
			{
				legalEntityId: string;
				name: string;
				actualsFrom: YearMonth;
				period: { start: YearMonth; end: YearMonth };
				description: string;
				createTemplatesForRegisters: boolean;
				basedOnActuals: boolean;
				basedOnYear: number;
				basedOnMonth: number;
				modules: string[];
				comparisonPlans: string[];
				companyId: string;
				thousands: boolean;
				dimensions: PlanDimension[];
			}
		>({
			query: ({ actualsFrom, period, companyId, legalEntityId, ...data }) => {
				return {
					method: "POST",
					url: `/v1/${companyId}/legal-entity/${legalEntityId}/plans`,
					body: {
						...data,
						actualsFrom: formatYYYYMMDD(dateFromYearMonth(actualsFrom)),
						periodFrom: formatYYYYMMDD(dateFromYearMonth(period.start)),
						periodTo: formatYYYYMMDD(endOfMonth(dateFromYearMonth(period.end))),
					},
				};
			},

			invalidatesTags: ["Plan"],
		}),

		copyPlan: builder.mutation<
			{ planId: string; taskId: string },
			{
				legalEntityId: string;
				name: string;
				actualsFrom: YearMonth;
				period: { start: YearMonth; end: YearMonth };
				description: string;
				basedOnPlanId: string;
				companyId: string;
				moduleCopyOptions: Partial<Record<ModuleRefType, string>> | undefined;
			}
		>({
			query: ({
				legalEntityId,
				basedOnPlanId,
				actualsFrom,
				period,
				...data
			}) => {
				return {
					method: "POST",
					url: `/v1/plan/legalentities/${legalEntityId}/plans/${basedOnPlanId}/copy`,
					body: {
						...data,
						actualsFrom: formatYYYYMMDD(dateFromYearMonth(actualsFrom)),
						periodFrom: formatYYYYMMDD(dateFromYearMonth(period.start)),
						periodTo: formatYYYYMMDD(endOfMonth(dateFromYearMonth(period.end))),
					},
				};
			},

			invalidatesTags: ["Plan"],
		}),

		getPlans: builder.query<
			DomainPlan[],
			{ companyDomainId: string; legalEntityId: string }
		>({
			query: ({ companyDomainId, legalEntityId }) => {
				return `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/plans`;
			},
			providesTags: (result) =>
				result
					? [...result.map(({ id }) => ({ type: "Plan" as const, id })), "Plan"]
					: ["Plan"],
		}),

		getPlansForCompanyDomain: builder.query<
			DomainPlan[],
			{ companyDomainId: string }
		>({
			query({ companyDomainId }) {
				return `/v1/company-domain/${companyDomainId}/plans`;
			},
			providesTags: (result) =>
				result
					? [...result.map(({ id }) => ({ type: "Plan" as const, id })), "Plan"]
					: ["Plan"],
		}),

		deletePlan: builder.mutation<
			void,
			{ planId: string; companyDomainId: string }
		>({
			query: ({ planId, companyDomainId }) => {
				return {
					method: "DELETE",
					url: `/v1/${companyDomainId}/plans/${planId}`,
				};
			},
			invalidatesTags: () => ["Plan"],
		}),

		getComparisonData: builder.query<
			(
				| Exclude<PlanComparisonData, { status: "exported" }>
				| (Extract<PlanComparisonData, { status: "exported" }> & {
						transactions: DomainPlanTransaction[];
				  })
			)[],
			{
				planId: string;
				companyDomainId: string;
				legalEntityId: string;
				comparisonPlans: string[];
			}
		>({
			queryFn: async (
				{ planId, comparisonPlans, companyDomainId, legalEntityId },
				_api,
				_extraOptions,
				baseQuery,
			) => {
				const result = await asyncMap(
					comparisonPlans,
					async (comparisonPlanId) => {
						const { data, error } = (await baseQuery({
							url: buildPlanRouteUrl(`/comparison-data/${comparisonPlanId}`, {
								companyDomainId,
								planId,
								legalEntityId,
							}),
						})) as QueryReturnValue<PlanComparisonData>;

						if (!data || error) {
							throw error;
						}

						if (data.status === "exported") {
							const { data: transactions } = await loadAllPages(
								({ page, pageSize }) =>
									baseQuery({
										url: buildPlanRouteUrl(
											`/transactions?page=${page}&pageSize=${pageSize}`,
											{
												companyDomainId,
												legalEntityId,
												planId: comparisonPlanId,
												version: "v2",
											},
										),
									}) as Promise<
										QueryReturnValue<
											OffsetBasedPaginatedResponse<DomainPlanTransaction>,
											FetchBaseQueryError,
											FetchBaseQueryMeta
										>
									>,
							);

							return {
								...data,
								transactions: transactions
									? transactions.map((transaction) => {
											return {
												...transaction,
												date: dateToPlainDate(
													new Date(transaction.date),
												).toString(),
											};
										})
									: [],
							};
						}

						return data;
					},
				);

				return {
					data: result,
				};
			},
			providesTags: (_result, _error, arg) => [
				"ComparisonData",
				{ type: "Plan", id: arg.planId },
			],
		}),

		checkPlanName: builder.query<
			boolean,
			{ name: string; legalEntityId: string; companyDomainId: string }
		>({
			query: ({ name, companyDomainId, legalEntityId }) => {
				return {
					method: "POST",
					url: `/v1/${companyDomainId}/plans/check-name`,
					body: {
						name,
						legalEntityId,
					},
				};
			},
		}),

		exportPlan: builder.mutation<
			void,
			{
				companyDomainId: string;
				planId: string;
			} & ExportPlanBody
		>({
			query: ({ companyDomainId, planId, transactions }) => ({
				method: "POST",
				url: `/v1/${companyDomainId}/plans/${planId}/export`,
				body: { transactions },
			}),
			invalidatesTags: (_result, _error, arg) => [
				{
					type: "Plan",
					id: arg.planId,
				},
			],
		}),

		getExportedTransactions: builder.query<
			DomainPlanTransaction[],
			{
				companyDomainId: string;
				planId: string;
				legalEntityId: string;
			}
		>({
			queryFn: async (
				{ companyDomainId, planId, legalEntityId },
				_api,
				_extraOptions,
				baseQuery,
			) => {
				const result = await loadAllPages(
					({ page, pageSize }) =>
						baseQuery({
							url: buildPlanRouteUrl(
								`/transactions?page=${page}&pageSize=${pageSize}`,
								{
									companyDomainId,
									planId,
									legalEntityId,
									version: "v2",
								},
							),
						}) as Promise<
							QueryReturnValue<
								OffsetBasedPaginatedResponse<DomainPlanTransaction>,
								FetchBaseQueryError,
								FetchBaseQueryMeta
							>
						>,
				);

				if ("error" in result) {
					return result;
				}

				return {
					...result,
					data: result.data.map((transaction) => ({
						...transaction,
						date: dateToPlainDate(new Date(transaction.date)).toString(),
					})),
				};
			},
			providesTags: (_result, _err, args) => [
				"ExportTransactions",
				{
					type: "ExportTransactions",
					id: args.planId,
				},
			],
		}),

		updateStatus: builder.mutation<
			void,
			{
				body: UpdatePlanStatus;
				planId: string;
				companyDomainId: string;
				legalEntityId: string;
			}
		>({
			query({ legalEntityId, body, companyDomainId, planId }) {
				return {
					url: buildPlanRouteUrl("/status", {
						companyDomainId,
						legalEntityId,
						planId,
					}),
					body,
					method: "put",
				};
			},
			invalidatesTags() {
				return ["Plan", "ExportTransactions"];
			},
		}),

		updatePlan: builder.mutation<
			void,
			{
				companyDomainId: string;
				legalEntityId: string;
				planId: string;
				name: string;
				description: string;
				modules: string[];
				comparisonPlans: string[];
				thousands: boolean;
			}
		>({
			query({ legalEntityId, companyDomainId, planId, ...body }) {
				return {
					url: buildPlanRouteUrl("", {
						companyDomainId,
						legalEntityId,
						planId,
					}),
					body,
					method: "put",
				};
			},
			invalidatesTags() {
				return [
					"Plan",
					"AccountRecipe",
					"Driver",
					"AccountLink",
					"AssetType",
					"FinancialAssetType",
					"PersonnelAgreement",
				];
			},
		}),
	}),
	overrideExisting: false,
});

export const {
	useLazyCheckPlanNameQuery,
	useCopyPlanMutation,
	useCreatePlanMutation,
	useGetPlansQuery,
	useGetPlanQuery,
	useDeletePlanMutation,
	useExportPlanMutation,
	useUpdateStatusMutation,
	useGetComparisonDataQuery,
	useUpdatePlanMutation,
	useGetExportedTransactionsQuery,
	useGetPlansForCompanyDomainQuery,
} = planApi;
