import { useCallback, useMemo } from "react";
import type { CompanyDomain } from "./types.js";
import { useLoadedCompanyDomains } from "src/routes/routeDataHooks.js";

export const useCompanyDomains = () => {
	const companyDomains = useLoadedCompanyDomains();

	const convertedCompanyDomains = useMemo(() => {
		return companyDomains.map<CompanyDomain>((companyDomain) => ({
			...companyDomain,
			id: `${companyDomain.id}`,
		}));
	}, [companyDomains]);

	const getById = useCallback(
		(companyDomainId: string | number) => {
			return (
				(convertedCompanyDomains ?? []).find(
					(companyDomain) => companyDomain.id === `${companyDomainId}`,
				) ?? null
			);
		},
		[convertedCompanyDomains],
	);

	return useMemo(
		() => ({
			companyDomains: convertedCompanyDomains ?? null,
			getById,
		}),
		[convertedCompanyDomains, getById],
	);
};

export const useCompanyDomainsOrThrow = () => {
	const { companyDomains, ...rest } = useCompanyDomains();

	return useMemo(
		() => ({
			companyDomains,
			getById: rest.getById,
		}),
		[companyDomains, rest.getById],
	);
};
