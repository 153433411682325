import { useMemo } from "react";
import { useParams } from "react-router";
import { invariant } from "../utils/invariant.js";
import { useCompanyDomainsOrThrow } from "./useCompanyDomains.js";

export const useCurrentCompanyDomainId = () => {
	const { companyId, companyDomainId } = useParams();

	const companyDomainIdToUse = companyDomainId ?? companyId;

	invariant(companyDomainIdToUse);

	return {
		companyDomainId: companyDomainIdToUse,
	};
};

/**
 * @deprecated Use `useLoadedCompanyDomain` or `useCurrentCompanyDomainId` instead
 */
export const useCurrentCompanyDomain = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();

	const { getById } = useCompanyDomainsOrThrow();

	const companyDomain = getById(companyDomainId);

	invariant(companyDomain, "Company domain not found");

	return useMemo(
		() => ({
			companyDomain,
		}),
		[companyDomain],
	);
};
