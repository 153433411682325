import type { CSSProperties, ReactNode } from "react";
import { clsx } from "clsx";

interface EmptyStateProps {
	createButton?: ReactNode | undefined;
	header: string;
	subHeader?: string | undefined;
	className?: string | undefined;
	style?: CSSProperties | undefined;
}

export const EmptyState = ({
	createButton,
	header,
	subHeader,
	className,
	style,
}: EmptyStateProps) => {
	const withSubHeader = subHeader !== undefined;
	const withCreateButton = createButton;
	return (
		<div
			className={clsx("flex flex-col items-center py-12", className)}
			style={style}
		>
			<div
				className={clsx(
					"text-gray-900 text-base font-medium text-center",
					withSubHeader || withCreateButton ? "mb-2" : undefined,
				)}
			>
				{header}
			</div>
			<div
				className={clsx(
					"text-gray-500 text-sm font-medium text-center max-w-prose",
					createButton ? "mb-6" : undefined,
				)}
			>
				{subHeader}
			</div>
			{createButton && <div className="space-x-4">{createButton}</div>}
		</div>
	);
};
