import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { EntityHierarchyLayout } from "../components/EntityHierarchyLayout.js";

export const LegalEntitiesPage = () => {
	const { t } = useTranslation();

	return (
		<EntityHierarchyLayout title={t("Group structure")}>
			<Outlet />
		</EntityHierarchyLayout>
	);
};
