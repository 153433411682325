import { createContext, type ReactNode, useMemo } from "react";
import type { User, UserSpecificAccessGroup } from "../service/Types/UserTypes";
import type { AccessGroupObject } from "src/common/service/Types/AccessGroup.js";
import {
	COMPANYADMIN,
	ROLE_PLANNING_ADMIN,
	SUPERADMIN,
} from "src/common/service/users";
import { useLoadedUser } from "src/routes/routeDataHooks";

export type Permission =
	| "DataSieUpload"
	| "DataLayer"
	| typeof ROLE_PLANNING_ADMIN
	| typeof COMPANYADMIN
	| typeof SUPERADMIN;

export type UserContextType =
	| {
			user: User;
			userIsSuperAdmin: () => boolean;
			hasPermissions: (
				companyDomainId: string | number,
				requiredPermissions: Permission[],
				mode?: "all" | "any" | undefined,
			) => boolean;
	  }
	| undefined;

export const UserContext = createContext<UserContextType>(undefined);

function useProvideUser() {
	const user = useLoadedUser();

	const accessGroupById = useMemo(() => {
		return user.accessGroups.reduce(
			(accessGroupsById, accessGroup) => {
				accessGroupsById[accessGroup.groupId] = accessGroup;
				return accessGroupsById;
			},
			{} as Record<number, UserSpecificAccessGroup>,
		);
	}, [user.accessGroups]);

	const accessGroupsByCompanyDomain = useMemo(() => {
		return user.accessGroups.reduce(
			(accessGroupsByCompanyDomain, accessGroup) => {
				const { companyId } = accessGroup;
				if (!accessGroupsByCompanyDomain[companyId]) {
					accessGroupsByCompanyDomain[companyId] = [];
				}
				accessGroupsByCompanyDomain[companyId].push(accessGroup);
				return accessGroupsByCompanyDomain;
			},
			{} as Record<string, UserSpecificAccessGroup[]>,
		);
	}, [user.accessGroups]);

	const accessGroupObjectsByCompanyDomain = useMemo(() => {
		return user.accessGroupsObjects.reduce(
			(accessGroupsByCompanyDomain, accessGroupObject) => {
				const accessGroup = accessGroupById[accessGroupObject.accessGroupId];
				if (!accessGroup) {
					console.warn("Access group not found");
					return accessGroupsByCompanyDomain;
				}

				const { companyId } = accessGroup;
				if (!accessGroupsByCompanyDomain[`${companyId}`]) {
					accessGroupsByCompanyDomain[`${companyId}`] = [];
				}
				accessGroupsByCompanyDomain[`${companyId}`].push(accessGroupObject);
				return accessGroupsByCompanyDomain;
			},
			{} as Record<string, AccessGroupObject[]>,
		);
	}, [user.accessGroupsObjects, accessGroupById]);

	return useMemo(() => {
		const userIsSuperAdmin = () => {
			return user.superAdmin;
		};

		const hasPermissions = (
			companyDomainId: string | number,
			permissions: Permission[],
			mode: "all" | "any" = "all",
		) => {
			const companyDomainIdAsString = `${companyDomainId}`;
			const accessGroupObjects =
				accessGroupObjectsByCompanyDomain[companyDomainIdAsString] ?? [];
			const modeMethod = mode === "all" ? "every" : "some";
			return permissions[modeMethod]((permission) => {
				if (permission === SUPERADMIN) {
					return userIsSuperAdmin();
				}

				// Treat CompanyAdmin and PlanningAdmin as permissions
				if (permission === COMPANYADMIN || permission === ROLE_PLANNING_ADMIN) {
					if (userIsSuperAdmin()) {
						return true;
					}
					const accessGroups =
						accessGroupsByCompanyDomain[companyDomainIdAsString];
					return accessGroups.some(
						(accessGroup) => accessGroup.name === permission,
					);
				}

				for (const accessGroupObject of accessGroupObjects) {
					if (accessGroupObject.objectId === permission) {
						return true;
					}
				}
				return false;
			});
		};

		return {
			user,
			hasPermissions,
			userIsSuperAdmin,
		};
	}, [user, accessGroupObjectsByCompanyDomain, accessGroupsByCompanyDomain]);
}

export function ProvideUser({
	children,
}: {
	children?: ReactNode | undefined;
}) {
	const user = useProvideUser();
	return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
