import { Outlet, ScrollRestoration } from "react-router";
import { ProvideUser } from "./common/context/useUser";

export const ProtectedApp = () => {
	return (
		<>
			<ProvideUser>
				<Outlet />
			</ProvideUser>
			<ScrollRestoration
				getKey={(location) => {
					return location.pathname;
				}}
			/>
		</>
	);
};
