import { useCallback, useMemo } from "react";
import { useLoadedLegalEntities } from "src/routes/routeDataHooks";

export const useLegalEntities = () => {
	const legalEntities = useLoadedLegalEntities();

	const getById = useCallback(
		(legalEntityId: string) =>
			(legalEntities ?? []).find(
				(legalEntity) => legalEntity.id === legalEntityId,
			) ?? null,
		[legalEntities],
	);

	const getByLeMasterKey = useCallback(
		(leMasterKey: string) =>
			(legalEntities ?? []).find(
				(legalEntity) => legalEntity.legalEntityMasterKey === leMasterKey,
			) ?? null,
		[legalEntities],
	);

	return useMemo(
		() => ({
			legalEntities: legalEntities ?? null,
			getById,
			getByLeMasterKey,
		}),
		[getById, getByLeMasterKey, legalEntities],
	);
};

export const useLegalEntitiesOrThrow = () => {
	const { legalEntities, ...rest } = useLegalEntities();

	return useMemo(
		() => ({
			legalEntities,
			getById: rest.getById,
			getByLeMasterKey: rest.getByLeMasterKey,
		}),
		[legalEntities, rest.getById, rest.getByLeMasterKey],
	);
};
