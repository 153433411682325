import type { SourceSystem } from "src/features/connector/sourceSystems";
import clsx from "clsx";
import { getSourceSystemMetadata } from "src/features/connector/metadata";

type Props = {
	sourceSystemName?: SourceSystem | undefined;
	className?: string | undefined;
};

export const SourceSystemLogo = ({ sourceSystemName, className }: Props) => {
	const { name, logoUrl } = getSourceSystemMetadata(
		sourceSystemName as SourceSystem,
	);

	return (
		<img
			src={logoUrl}
			className={clsx("rounded-xl size-11", className)}
			alt={name}
			title={name}
		/>
	);
};
