import { Navigate, useSearchParams } from "react-router";

export const FortnoxExternalCallbackPage = () => {
	const [searchParams] = useSearchParams();
	const isSuccess = searchParams.has("integrationId");

	if (isSuccess) {
		return "✅ Thank you. You can now close this page.";
	}
	const error = searchParams.get("error");
	if (error === "AUTHORIZATION_TIMEOUT") {
		return "❌ The link has expired. Please request a new link.";
	}

	if (!error) {
		return <Navigate to="/" />;
	}

	return "❌ Unknown error";
};
