import { Auth0Client } from "@auth0/auth0-spa-js";
import { auth0Audience, auth0ClientId, auth0Domain } from "src/env";

export const auth0Client = new Auth0Client({
	domain: auth0Domain,
	clientId: auth0ClientId,
	authorizationParams: {
		audience: auth0Audience,
		redirect_uri: window.location.origin,
	},
});

export const getAccessToken = () => {
	return auth0Client.getTokenSilently();
};

export const logout = async () => {
	await auth0Client.logout({
		logoutParams: {
			returnTo: window.location.origin,
		},
	});
};
