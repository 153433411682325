import {
	type Placement,
	arrow,
	autoUpdate,
	flip,
	offset,
	safePolygon,
	shift,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useRole,
} from "@floating-ui/react";
import { useState, useMemo, useRef, useCallback } from "react";
import { ARROW_HEIGHT } from "./tooltipConstants.js";

const PADDING = 16;
const GAP = 4;

export interface UseTooltipOptions {
	initialOpen?: boolean | undefined;
	placement?: Placement | undefined;
	open?: boolean | undefined;
	onOpenChange?: ((open: boolean) => void) | undefined;
	onClose?: (() => void) | undefined;
	delay?: number | undefined;
}

export function useTooltip({
	initialOpen = false,
	placement = "top",
	open: controlledOpen,
	onOpenChange: setControlledOpen,
	onClose,
	delay,
}: UseTooltipOptions) {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;
	const arrowRef = useRef(null);

	const setOpenCtx = useCallback(
		(open: boolean) => {
			setOpen(open);
			if (!open) {
				onClose?.();
			}
		},
		[onClose, setOpen],
	);

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpenCtx,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(ARROW_HEIGHT + GAP),
			flip({
				crossAxis: placement.includes("-"),
				fallbackAxisSideDirection: "end",
				padding: 0,
			}),
			shift({ padding: PADDING }),
			arrow({ element: arrowRef }),
		],
	});

	const context = data.context;

	const hover = useHover(context, {
		move: false,
		enabled: controlledOpen == null,
		handleClose: safePolygon(),
		delay: delay ? { open: delay, close: 0 } : 0,
	});
	const focus = useFocus(context, {
		enabled: controlledOpen == null,
	});
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });

	const interactions = useInteractions([hover, focus, dismiss, role]);

	return useMemo(
		() => ({
			open,
			setOpen: setOpenCtx,
			...interactions,
			...data,
			arrowRef,
		}),
		[open, setOpenCtx, interactions, data],
	);
}
