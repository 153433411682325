import clsx from "clsx";
import type { ReactNode } from "react";
import { useNavigation } from "react-router";

export const AppFrame = ({
	children,
}: {
	children?: ReactNode | undefined;
}) => {
	return (
		<div className="h-screen w-full flex-col overflow-auto sm:flex sm:flex-row">
			{children}
		</div>
	);
};

export const MainFrame = ({
	children,
}: {
	children?: ReactNode | undefined;
}) => {
	const navigation = useNavigation();
	return (
		<main
			className={clsx(
				"min-h-screen grow overflow-x-hidden transition-opacity",
				navigation.state === "loading" ? "opacity-40" : null,
			)}
		>
			{children}
		</main>
	);
};
