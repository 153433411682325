import { emptyFusionHubApi } from "./emptyFusionHubApi.ts";
import type {
	PaginatedParams,
	FusionhubIntegration,
	PaginatedResponse,
	ErLegalEntity,
	ErDocumentation,
	FusionHubJob,
	FusionHubJobStatus,
} from "./types.ts";

export type FusionHubExtendedIntegration = {
	integration: FusionhubIntegration;
	lastSuccessfulSyncAt: string | null;
	currentStatus: "waiting" | "running" | "completed" | "failed" | null;
};

export const fusionHubApi = emptyFusionHubApi.injectEndpoints({
	endpoints: (builder) => ({
		getErDocumentation: builder.query<ErDocumentation, void>({
			query: () => "/er/documentation",
		}),
		getAllIntegrations: builder.query<
			PaginatedResponse<FusionHubExtendedIntegration>,
			{ companyDomainId: string } & PaginatedParams
		>({
			query: (params) => ({
				url: "/integrations/extended",
				params,
			}),
			providesTags: (result) => [
				{
					type: "Integrations",
				},
				...(result?.items ?? []).map((item) => {
					return { type: "Integrations" as const, id: item.integration.id };
				}),
			],
		}),
		getErIntegrations: builder.query<
			PaginatedResponse<FusionhubIntegration>,
			{ companyDomainId: string } & PaginatedParams
		>({
			query: (params) => ({
				url: "/er/integrations",
				params,
			}),
			providesTags: (result) => [
				{
					type: "Integrations",
				},
				...(result?.items ?? []).map((item) => {
					return { type: "Integrations" as const, id: item.id };
				}),
			],
		}),

		getErLegalEntity: builder.query<ErLegalEntity, { integrationId: number }>({
			query: ({ integrationId }) =>
				`/er/integrations/${integrationId}/legal-entity`,
		}),

		triggerSync: builder.mutation<void, { integrationIds: number[] }>({
			query: ({ integrationIds }) => ({
				url: "/er/integrations/sync",
				method: "POST",
				body: { integrationIds },
			}),
			invalidatesTags: (_result, _error, { integrationIds }) => {
				return integrationIds.flatMap((integrationId) => [
					{ type: "SyncStatus", id: integrationId },
					{ type: "Integrations", id: integrationId },
					{ type: "JobInIntegration", id: integrationId },
				]);
			},
		}),

		removeIntegration: builder.mutation<void, { integrationId: number }>({
			query: ({ integrationId }) => ({
				url: `/integrations/${integrationId}`,
				method: "DELETE",
			}),
			invalidatesTags: (_result, _error, { integrationId }) => [
				{ type: "SyncStatus", id: integrationId },
				{ type: "Integrations", id: integrationId },
				{ type: "JobInIntegration", id: integrationId },
			],
		}),

		getJobs: builder.query<
			PaginatedResponse<FusionHubJob>,
			{
				companyDomainId?: string | undefined;
				integrationId?: string | undefined;
				status?: FusionHubJobStatus[] | undefined;
				jobNames?: string[] | undefined;
			} & PaginatedParams
		>({
			query({ status, jobNames, ...params }) {
				const searchParams = new URLSearchParams();
				if (status) {
					for (const s of status) {
						searchParams.append("status", s);
					}
				}

				if (jobNames) {
					for (const j of jobNames) {
						searchParams.append("jobNames", j);
					}
				}
				return {
					url: `/jobs?${searchParams.toString()}`,
					params: params,
				};
			},
			providesTags(result, _error, args) {
				return [
					args.integrationId
						? { type: "JobInIntegration" as const, id: args.integrationId }
						: null,
					...(result
						? result.items.map((job) => ({ type: "Job" as const, id: job.id }))
						: [{ type: "Job" as const }]),
				];
			},
		}),

		getJob: builder.query<FusionHubJob, { jobId: string }>({
			query(params) {
				return {
					url: `/jobs/${params.jobId}`,
					params,
				};
			},
			providesTags(result, _error, args) {
				return [
					{ type: "Job" as const, id: `${args.jobId}` },
					result && result.integrationId !== null
						? { type: "JobInIntegration" as const, id: result.integrationId }
						: null,
				];
			},
		}),

		getJobNames: builder.query<
			string[],
			{
				companyDomainId?: string | undefined;
				integrationId?: string | undefined;
			}
		>({
			query(params) {
				return {
					url: "/jobs/job-names",
					params,
				};
			},
		}),

		getIntegration: builder.query<
			FusionhubIntegration,
			{ integrationId: string }
		>({
			query({ integrationId }) {
				return {
					url: `/integrations/${integrationId}`,
				};
			},
			providesTags: (_result, _error, { integrationId }) => [
				{ type: "Integrations", id: integrationId },
			],
		}),
	}),
});

export const {
	useGetAllIntegrationsQuery,
	useGetErIntegrationsQuery,
	useGetErLegalEntityQuery,
	useGetErDocumentationQuery,
	useTriggerSyncMutation,
	useRemoveIntegrationMutation,
	useGetJobsQuery,
	useGetJobQuery,
	useGetJobNamesQuery,
	useGetIntegrationQuery,
} = fusionHubApi;
