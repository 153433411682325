import { useCurrentCompanyDomainId } from "src/common/company-domain/useCurrentCompanyDomain";
import { exopenApi } from "src/common/redux/api/exopenApi";
import { fusionHubApi } from "src/common/service/fusionHub/fusionHubApi";
import { fortnoxApi } from "src/common/service/fusionHub/integrations/fortnoxApi";
import { mergeCrmApi } from "src/common/service/fusionHub/integrations/mergeCrmApi";
import { nexusApi } from "src/common/service/nexus/nexusApi";

export const useLoadedCompanyDomain = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();
	const { data } = exopenApi.endpoints.getCompany.useQueryState({
		companyId: companyDomainId,
	});

	if (!data) {
		throw new Error("Company domain not found");
	}

	return data;
};

export const useLoadedUser = () => {
	const { data } = exopenApi.endpoints.getCurrentUser.useQueryState();

	if (!data) {
		throw new Error("User not found");
	}

	return data;
};

export const useLoadedCompanyDomains = () => {
	const { data } = exopenApi.endpoints.getAllCompanies.useQueryState();

	if (!data) {
		throw new Error("Company domains not found");
	}

	return data;
};

export const useLoadedLegalEntities = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();
	const { data } =
		nexusApi.endpoints.getLegalEntities.useQueryState(companyDomainId);

	if (!data) {
		throw new Error("Legal entities not found");
	}

	return data;
};

export const useLoadedCompanyHierarchy = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();
	const { data } =
		nexusApi.endpoints.getCompanyHierarchy.useQueryState(companyDomainId);

	if (!data) {
		throw new Error("Company hierarchy not found");
	}

	return data;
};

export const useLoadedPipelineRuns = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();
	const { data } = exopenApi.endpoints.getPipelineRuns.useQueryState({
		companyId: companyDomainId,
	});

	if (!data) {
		throw new Error("Pipeline runs not found");
	}

	return data;
};

export const useLoadedFusionHubIntegrations = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();
	const { data } = fusionHubApi.endpoints.getAllIntegrations.useQueryState({
		companyDomainId,
	});

	if (!data) {
		throw new Error("FusionHub integrations not found");
	}

	return data;
};

export const useLoadedFusionHubMergeCrmIntegrations = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();
	const { data } = mergeCrmApi.endpoints.getMergecrmIntegrations.useQueryState({
		companyDomainId,
	});

	if (!data) {
		throw new Error("Merge CRM integrations not found");
	}

	return data;
};

export const useLoadedNexusIntegrations = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();
	const { data } = nexusApi.endpoints.getIntegrations.useQueryState({
		companyDomainId,
	});

	if (!data) {
		throw new Error("Nexus integrations not found");
	}

	return data;
};

export const useLoadedFortnoxStatus = () => {
	const { data } = fortnoxApi.endpoints.getStatus.useQueryState();

	if (!data) {
		throw new Error("Fortnox status not found");
	}

	return data;
};
