import { emptyFusionHubApi } from "src/common/service/fusionHub/emptyFusionHubApi";
import type { FusionhubIntegration } from "src/common/service/fusionHub/types";

export type SieIntegrationConfig = {
	integrationId: number;
	legalEntityName: string;
	organisationNumber: string;
};

export type SieAccount = {
	name: string | null;
	number: string;
	incomingBalance: number | null;
	transactionSum: number | null;
};

export type SieFilePreviewResultData = {
	startDate: string;
	endDate: string;
	currency: string;
	objects: number;
	accounts: number;
	vouchers: number;
	incomingBalances: number;
	dimensions: number;
	total: {
		incomingBalance: number;
		transactionSum: number;
	};
	organisationName: string;
	organisationNumber: string;
	accountDetails: Record<string, Omit<SieAccount, "number">>;
	actualsUntil: string;
};

export type SieFileSummaryOutput = {
	result: SieFilePreviewResultData | null;
	errors: string[];
	warnings: string[];
	infos: string[];
};
export type SieFileSummaryStatus = "SUCCESS" | "FAILURE" | "PROCESSING";

export type SieFileMetadata =
	| {
			organisationNumber: string;
			organisationName: string;
			startDate: string;
			endDate: string;
			currency: string;
	  }
	| {
			errors: string[];
	  };

export type SieIntegrationAggregated = {
	integration: FusionhubIntegration;
	config: SieIntegrationConfig;
};

export type SieFile = {
	id: string;
	integrationId: number;
	sourceId: string;
	encoding: string | null;
	startDate: string;
	endDate: string;
	isPublished: boolean;
	uploadedBy: string | null;
	name: string;
	createdAt: string;
};

const sieApi = emptyFusionHubApi.injectEndpoints({
	endpoints: (builder) => ({
		createSieIntegration: builder.mutation<
			{
				integration: FusionhubIntegration;
				file: SieFile;
			},
			{
				companyDomainId: string;
				sourceId: string;
				fileName: string;
			}
		>({
			query: ({ companyDomainId, sourceId, fileName }) => ({
				url: `/integrations/sie/${companyDomainId}/integrations`,
				method: "POST",
				body: { sourceId, fileName },
			}),
			invalidatesTags: [{ type: "Integrations" }],
		}),
		getSieFileUploadUrl: builder.query<
			{ uploadUrl: string; sourceId: string },
			{ companyDomainId: string }
		>({
			query: ({ companyDomainId }) => ({
				url: `/integrations/sie/${companyDomainId}/file-upload-url`,
				method: "GET",
			}),
		}),
		getSieFileDownloadUrl: builder.query<
			{ downloadUrl: string },
			{ integration: FusionhubIntegration; file: Pick<SieFile, "id"> }
		>({
			query: ({ file, integration }) => ({
				url: `/integrations/${integration.id}/sie-raw/files/${file.id}/download-url`,
				method: "GET",
			}),
		}),

		previewSieFile: builder.query<
			SieFileMetadata,
			{ companyDomainId: string; sourceId: string }
		>({
			query: ({ companyDomainId, sourceId }) => ({
				url: `/integrations/sie/${companyDomainId}/files/${sourceId}`,
			}),
		}),
		summarizeSieFile: builder.query<
			{
				output: SieFileSummaryOutput | null;
				status: SieFileSummaryStatus;
			},
			{ integrationId: number; fileId: number }
		>({
			query: ({ integrationId, fileId }) => ({
				url: `/integrations/${integrationId}/sie-raw/files/${fileId}/summary`,
			}),
		}),
		addSieFileToIntegration: builder.mutation<
			SieFile,
			{ sourceId: string; fileName: string; integrationId: number }
		>({
			query: ({ sourceId, fileName, integrationId }) => ({
				url: `/integrations/${integrationId}/sie-raw/files`,
				method: "POST",
				body: { sourceId, fileName },
			}),
			invalidatesTags: () => [{ type: "SieFile" }],
		}),
		publishSieFile: builder.mutation<
			void,
			{ fileId: string; integrationId: number; replaceOverlapping: boolean }
		>({
			query: ({ fileId, integrationId, replaceOverlapping }) => ({
				url: `/integrations/${integrationId}/sie-raw/files/${fileId}/published`,
				method: "PUT",
				body: { replaceOverlapping },
			}),
			invalidatesTags: () => [{ type: "SieFile" }],
		}),
		unpublishSieFile: builder.mutation<
			void,
			{ fileId: string; integrationId: number }
		>({
			query: ({ fileId, integrationId }) => ({
				url: `/integrations/${integrationId}/sie-raw/files/${fileId}/published`,
				method: "DELETE",
			}),
			invalidatesTags: () => [{ type: "SieFile" }],
		}),
		getSieIntegrationsAggregated: builder.query<
			{
				integrationsAggregated: {
					integration: FusionhubIntegration;
					config: SieIntegrationConfig;
				}[];
			},
			{ companyDomainId: string }
		>({
			query: ({ companyDomainId }) => ({
				url: `/integrations/sie/${companyDomainId}/integrations-aggregated`,
			}),
			providesTags: () => [
				{
					type: "Integrations",
				},
			],
		}),
		getSieIntegrationAggregated: builder.query<
			{
				integration: FusionhubIntegration;
				config: SieIntegrationConfig;
			},
			{ integrationId: number }
		>({
			query: ({ integrationId }) => ({
				url: `/integrations/${integrationId}/sie-raw/integration-aggregated`,
			}),
			providesTags: () => [
				{
					type: "Integrations",
				},
			],
		}),
		getSieFiles: builder.query<
			{
				files: SieFile[];
			},
			{ integrationId: number }
		>({
			query: ({ integrationId }) => ({
				url: `/integrations/${integrationId}/sie-raw/files`,
			}),
			providesTags: () => [{ type: "SieFile" }],
		}),
		getSieFile: builder.query<
			{
				file: SieFile;
			},
			{ integrationId: number; fileId: number }
		>({
			query: ({ integrationId, fileId }) => ({
				url: `/integrations/${integrationId}/sie-raw/files/${fileId}`,
			}),
			providesTags: () => [{ type: "SieFile" }],
		}),
		removeSieFileFromIntegration: builder.mutation<
			void,
			{ fileId: string; integrationId: number }
		>({
			query: ({ fileId, integrationId }) => ({
				url: `/integrations/${integrationId}/sie-raw/files/${fileId}`,
				method: "DELETE",
			}),
			invalidatesTags: () => [{ type: "SieFile" }],
		}),
	}),
	overrideExisting: false,
});

export const {
	useCreateSieIntegrationMutation,
	useGetSieIntegrationsAggregatedQuery,
	useGetSieIntegrationAggregatedQuery,
	useLazyGetSieFileUploadUrlQuery,
	useLazyGetSieFileDownloadUrlQuery,
	usePreviewSieFileQuery,
	useLazyPreviewSieFileQuery,
	useSummarizeSieFileQuery,
	useAddSieFileToIntegrationMutation,
	useGetSieFilesQuery,
	useGetSieFileQuery,
	usePublishSieFileMutation,
	useUnpublishSieFileMutation,
	useRemoveSieFileFromIntegrationMutation,
} = sieApi;
