import { Outlet } from "react-router";
import { ProvideLegacyLegalEntities } from "../common/context/LegacyLegalEntityContext";
import { ProvideLegalEntityMode } from "../common/context/LegalEntityModeContext";
import { Sidebar } from "../features/sidebar/Sidebar";
import { AppFrame, MainFrame } from "./ProtectedRoutes";
import { PipeLineProgressLoader } from "../features/pipelineProgressLoader/PipelineProgressLoader.js";
import { CompanySidebarPart } from "../features/sidebar/CompanySidebarPart.js";

const CompanyDomainContent = ({
	sidebarOpen,
}: {
	sidebarOpen?: boolean | undefined;
}) => {
	return (
		<ProvideLegacyLegalEntities>
			<ProvideLegalEntityMode>
				<AppFrame>
					<Sidebar
						defaultOpen={sidebarOpen}
						renderFooter={() => <PipeLineProgressLoader />}
						renderContent={({ sidebarOpen }) => (
							<CompanySidebarPart sidebarOpen={sidebarOpen} />
						)}
					/>
					<MainFrame>
						<Outlet />
					</MainFrame>
				</AppFrame>
			</ProvideLegalEntityMode>
		</ProvideLegacyLegalEntities>
	);
};

export const CompanyRouteLayout = ({
	sidebarOpen,
}: {
	sidebarOpen?: boolean;
}) => {
	return <CompanyDomainContent sidebarOpen={sidebarOpen} />;
};
