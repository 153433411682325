import { type ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "../common/utils/string-utils/capitlize";
import {
	type LocaleGetMonth,
	LocaleContext,
	type FormatRange,
	type FormatRangeParts,
	type Format,
} from "./useLocale";
import {
	type Duration,
	formatDuration as durationFormat,
} from "src/common/utils/date-utils/getDuration";

const DIVISIONS: { amount: number; name: Intl.RelativeTimeFormatUnit }[] = [
	{ amount: 60, name: "seconds" },
	{ amount: 60, name: "minutes" },
	{ amount: 24, name: "hours" },
	{ amount: 7, name: "days" },
	{ amount: 4.34524, name: "weeks" },
	{ amount: 12, name: "months" },
	{ amount: Number.POSITIVE_INFINITY, name: "years" },
];

function formatTimeAgo(formatter: Intl.RelativeTimeFormat, date: Date) {
	const now = new Date();
	let duration = (date.getTime() - now.getTime()) / 1000;

	for (let i = 0; i <= DIVISIONS.length; i++) {
		const division = DIVISIONS[i];
		if (Math.abs(duration) < division.amount) {
			return formatter.format(Math.round(duration), division.name);
		}
		duration /= division.amount;
	}

	throw new Error("No division found");
}

const formatRangeCache: Record<string, Intl.DateTimeFormat> = {};
const formatCache: Record<string, Intl.DateTimeFormat> = {};

interface ProvideLocaleProps {
	children?: ReactNode | undefined;
}

export const ProvideLocale = ({ children }: ProvideLocaleProps) => {
	const { i18n } = useTranslation();

	const longMonthFormatter = useMemo(() => {
		return new Intl.DateTimeFormat(i18n.language, { month: "long" });
	}, [i18n.language]);
	const shortMonthFormatter = useMemo(() => {
		return new Intl.DateTimeFormat(i18n.language, { month: "short" });
	}, [i18n.language]);

	const getMonthsInYear = useCallback(() => {
		return [...Array(12).keys()].map((m) =>
			capitalize(longMonthFormatter.format(new Date(1987, m))),
		);
	}, [longMonthFormatter]);

	const getMonth = useCallback<LocaleGetMonth>(
		(monthOrDate, type = "long") => {
			const formatter =
				type === "long" ? longMonthFormatter : shortMonthFormatter;
			return capitalize(
				formatter.format(
					typeof monthOrDate === "number"
						? new Date(1987, monthOrDate)
						: monthOrDate,
				),
			);
		},
		[longMonthFormatter, shortMonthFormatter],
	);

	const formatRange = useCallback<FormatRange>(
		(start, end) => {
			let formatter: Intl.DateTimeFormat;
			const cached = formatRangeCache[i18n.language];
			if (cached) {
				formatter = cached;
			} else {
				formatter = new Intl.DateTimeFormat(i18n.language, {
					year: "numeric",
					month: "short",
				});
				formatRangeCache[i18n.language] = formatter;
			}

			return formatter.formatRange(start, end);
		},
		[i18n.language],
	);

	const formatRangeParts = useCallback<FormatRangeParts>(
		(start, end) => {
			return new Intl.DateTimeFormat(i18n.language, {
				year: "numeric",
				month: "long",
			}).formatRangeToParts(start, end);
		},
		[i18n.language],
	);

	const format = useCallback<Format>(
		(date, options) => {
			try {
				const cacheKey = `${i18n.language}-${JSON.stringify(options)}`;
				const cached = formatCache[cacheKey];
				let formatter: Intl.DateTimeFormat;
				if (cached) {
					formatter = cached;
				} else {
					formatter = new Intl.DateTimeFormat(i18n.language, options);
					formatCache[cacheKey] = formatter;
				}

				return formatter.format(date);
			} catch (e) {
				console.error(`Failed to format time value: ${date}`);
				throw e;
			}
		},
		[i18n.language],
	);

	const relativeFormatter = useMemo(() => {
		return new Intl.RelativeTimeFormat(i18n.language);
	}, [i18n.language]);

	const formatRelative = useCallback(
		(date: Date) => {
			return formatTimeAgo(relativeFormatter, date);
		},
		[relativeFormatter],
	);

	const numberFormatter = useMemo(() => {
		return new Intl.NumberFormat(i18n.language);
	}, [i18n.language]);

	const formatNumber = useCallback(
		(number: number | bigint) => {
			return numberFormatter.format(number);
		},
		[numberFormatter],
	);

	const durationFormatter = useMemo(() => {
		if (!("DurationFormat" in Intl)) {
			return {
				format: (input: Duration) => {
					return durationFormat(input, { shortUnits: true });
				},
			};
		}

		const formatter = new (Intl as any).DurationFormat(i18n.language, {
			style: "narrow",
		});
		return formatter;
	}, [i18n.language]);

	const formatDuration = useCallback(
		(duration: Duration) => {
			return durationFormatter.format(duration);
		},
		[durationFormatter],
	);

	return (
		<LocaleContext.Provider
			value={{
				getMonthsInYear,
				getMonth,
				formatRange,
				format,
				formatRangeParts,
				formatRelative,
				lang: i18n.language,
				formatNumber,
				formatDuration,
			}}
		>
			{children}
		</LocaleContext.Provider>
	);
};
