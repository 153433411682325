import {
	type DimType,
	type DomainModule,
	type DomainAccountingObject,
	type DomainOrganizationalUnit,
	type ModuleCounts,
	buildPlanRouteUrl,
	type OffsetBasedPaginatedResponse,
} from "@exopengithub/planning-api-shared";
import type { Activity, ActivityUnion } from "../types";
import type { EntityState } from "@reduxjs/toolkit";
import { organizationalUnitsAdapter } from "../utils/organizationalUnitsAdapter";
import { accountingObjectsAdapter } from "../utils/accountingObjectsAdapter";
import { modulesAdapter } from "../utils/modulesAdapter";
import { emptyPlanningApi } from "./emptyPlanningApi.js";

export const planningApi = emptyPlanningApi.injectEndpoints({
	endpoints: (builder) => ({
		//#region Modules
		getModules: builder.query<EntityState<DomainModule, string>, void>({
			query() {
				return "/v1/module";
			},
			transformResponse(data: DomainModule[]) {
				return modulesAdapter.addMany(modulesAdapter.getInitialState(), data);
			},
		}),

		getCompanyModules: builder.query<DomainModule[], { companyId: string }>({
			query({ companyId }) {
				return `/v1/company/${companyId}/modules`;
			},
			providesTags: ["Module"],
		}),

		updateCompanyModules: builder.mutation<
			void,
			{ companyDomainId: string; modules: string[] }
		>({
			query({ modules, companyDomainId }) {
				return {
					method: "PUT",
					url: `/v1/company/${companyDomainId}/modules`,
					body: { modules },
				};
			},
			invalidatesTags: ["Module"],
		}),

		getModuleCounts: builder.query<
			ModuleCounts,
			{ companyDomainId: string; planId: string; legalEntityId: string }
		>({
			query({ planId, legalEntityId, companyDomainId }) {
				return buildPlanRouteUrl("/module-counts", {
					companyDomainId,
					legalEntityId,
					planId,
				});
			},
			providesTags() {
				return ["ModuleCount"];
			},
		}),
		//#endregion

		//#region Activity
		getPlanActivity: builder.query<
			Activity<ActivityUnion>[],
			{ planId: string; legalEntityId: string; companyDomainId: string }
		>({
			query: ({ planId, legalEntityId, companyDomainId }) =>
				buildPlanRouteUrl("/activity", {
					companyDomainId,
					planId,
					legalEntityId,
				}),
			providesTags: (result) =>
				result
					? [
							...result.map(({ id }) => ({
								type: "Activity" as const,
								id,
							})),
							"Activity",
						]
					: ["Activity"],
		}),
		//#endregion

		//#region Accounting objects
		getAccountingObject: builder.query<
			{
				items: EntityState<DomainAccountingObject, string>;
				pages: number;
				total: number;
			},
			{
				planId: string;
				dimType: DimType;
				companyDomainId: string;
				legalEntityId: string;
				page?: number;
				pageSize?: number;
				filterName?: string;
				filterActive?: boolean;
				sortDirection?: "asc" | "desc";
				orderBy?: "name" | "createdAt" | "code" | "active" | "syncStatus";
			}
		>({
			query: ({
				planId,
				dimType,
				companyDomainId,
				orderBy,
				filterName,
				page,
				sortDirection,
				pageSize,
				filterActive,
				legalEntityId,
			}) => {
				return {
					url: buildPlanRouteUrl(`/accounting-objects/${dimType}`, {
						planId,
						legalEntityId,
						companyDomainId,
					}),
					params: {
						page,
						filterName,
						sortDirection,
						orderBy,
						pageSize,
						filterActive,
					},
				};
			},
			transformResponse: (
				data: OffsetBasedPaginatedResponse<DomainAccountingObject>,
			) => {
				return {
					...data,
					items: accountingObjectsAdapter.addMany(
						accountingObjectsAdapter.getInitialState(),
						data.items,
					),
				};
			},
			providesTags: (result) =>
				result
					? [
							...result.items.ids.map((id) => ({
								type: "AccountingObject" as const,
								id,
							})),
							"AccountingObject",
						]
					: ["AccountingObject"],
		}),

		toggleAccountingObject: builder.mutation<
			unknown,
			{
				id: string;
				active: boolean;
				planId: string;
				companyDomainId: string;
				toggleAll?: DimType;
			}
		>({
			query: ({ id, active, planId, companyDomainId, toggleAll }) => {
				return {
					method: "PUT",
					url: `/v1/${companyDomainId}/plans/${planId}/accountingobjects/${id}/toggle`,
					body: {
						active,
						toggleAll,
					},
				};
			},
			invalidatesTags: (_result, _error, arg) => [
				{ type: "AccountingObject", id: arg.id },
				"AccountingObject",
			],
		}),
		//#endregion

		//#region Organizational units
		getOrganizationalUnit: builder.query<
			EntityState<DomainOrganizationalUnit, string>,
			{ planId: string; companyDomainId: string }
		>({
			query: ({ planId, companyDomainId }) =>
				`/v1/${companyDomainId}/plans/${planId}/organizationalunits`,
			transformResponse: (data: DomainOrganizationalUnit[]) => {
				return organizationalUnitsAdapter.addMany(
					organizationalUnitsAdapter.getInitialState(),
					data,
				);
			},
			providesTags: (result) =>
				result
					? [
							...result.ids.map((id) => ({
								type: "OrganizationalUnit" as const,
								id,
							})),
							"OrganizationalUnit",
						]
					: ["OrganizationalUnit"],
		}),

		toggleOrganizationalUnit: builder.mutation<
			unknown,
			{
				id: string;
				active: boolean;
				planId: string;
				companyDomainId: string;
			}
		>({
			query: ({ id, active, planId, companyDomainId }) => {
				return {
					method: "PUT",
					url: `/v1/${companyDomainId}/plans/${planId}/organizationalunits/${id}/toggle`,
					body: {
						active,
					},
				};
			},
			invalidatesTags: (_result, _error, arg) => [
				{ type: "OrganizationalUnit", id: arg.id },
			],
		}),
		//#endregion

		//#region Sync
		syncDim: builder.mutation<
			unknown,
			{
				planId: string;
				dimType: DimType | "costcenter";
				companyDomainId: string;
				legalEntityMasterKey: string;
			}
		>({
			query: ({ planId, dimType, companyDomainId, legalEntityMasterKey }) => {
				return {
					method: "POST",
					url: `/v1/${companyDomainId}/plans/${planId}/sync/${dimType}`,
					body: {
						legalEntityMasterKey,
					},
				};
			},
			invalidatesTags: (_result, _error, arg) => {
				return [
					arg.dimType === "costcenter"
						? "OrganizationalUnit"
						: "AccountingObject",
				];
			},
		}),
		//#endregion
	}),
	overrideExisting: false,
});

export const {
	useGetModulesQuery,
	useGetPlanActivityQuery,
	useGetCompanyModulesQuery,
	useGetAccountingObjectQuery,
	useToggleAccountingObjectMutation,
	useGetOrganizationalUnitQuery,
	useToggleOrganizationalUnitMutation,
	useSyncDimMutation,
	useUpdateCompanyModulesMutation,
	useGetModuleCountsQuery,
} = planningApi;
